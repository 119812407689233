<template>
  <div class="ventas">
    <h1 style="color: #FE195E">Mis Ventas</h1>
    <section>

          <template>
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="#FE195E"
                dark
                show-arrows
                centered
              >
                <v-tab
                  v-for="item in items"
                  :key="item.tab"
                  :class="{}"
                >
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="item in items"
                  :key="item.tab"
                >
                  <v-card flat>
                    <v-card-text style="background: #f897b4; min-height: 700px;" v-if="item.tab == 'Ventas Totales'">
                      <Table_all :prices="priceTotal" :cantidad="cantidad"></Table_all>
                    </v-card-text>
                    <v-card-text style="background: #f897b4; min-height: 700px;" v-if="item.tab == 'Datos Producto'">
                      <Table_products v-for="product in products" :key="product._id" :products="product" :prices="prices" :types="type_plan"></Table_products>
                    </v-card-text>
                    <v-card-text style="background: #f897b4; min-height: 700px;" v-if="item.tab == 'Datos Persona'">
                      <Table_people v-for="product in products" :key="product._id" :products="product"></Table_people>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </template>

      </section>
    </div>
</template>

<script>
import Table_products from '@/components/garantias/Table-products.vue';
import Table_all from '@/components/garantias/Table-all.vue';
import Table_people from '@/components/garantias/Table-people.vue';
import guaranteeService from '@/services/garantias.service'

export default {
  name: "Ventas",
  data:()=>({
    title: '',
    people: [],
    prices: [],
    plans: [],
    products: [],
    type_plan: [],
    priceTotal: 0,
    cantidad: 0,
    aux: [],
    tab: null,
    items: [
        { tab: 'Ventas Totales'},
        { tab: 'Datos Persona'},
        { tab: 'Datos Producto'},
    ],
  }),
  components: {
    Table_all,
    Table_people,
    Table_products,
  },
  mounted() {
    this.getPlans();
  },
  methods:{
    //Funcion para traer planes del Sponsor
    async getPlans(){
      //Se realiza el llamado al servicio
      this.plans = await guaranteeService.getPlans().then((response) => {
        //Una vez obtenida la respuesta, se recorren los datos
        response.data.forEach(plan => {
            //Se establecen filtros para la busqueda de las products
            var filters_product ={
              id: plan._id,
              prices: plan.values,
              type: plan.type,
            }
            //Se llama a la función que llama al servicio que trae las products
            this.getProduct(filters_product)
        });
      }) 
      //Se almacena la información final del auxiliar creado en el servicio que trae las products
      this.products.push(this.aux)
    },
    //Funcion para traer products
    async getProduct(filters_product){
      //Se realiza el llamado al servicio
      await guaranteeService.getProduct(filters_product).then((response) => {
        //Una vez obtenida la respuesta, se recorren los datos que no estén vacios
        if(response.data.length != 0){
          response.data.forEach(product => {
            //Se almacena cada insurance en un auxiliar
            this.aux.push(product)
            //Se cuentan la cantidad de products
            this.cantidad = this.cantidad + 1
            //Se recorren los precios del plan
            filters_product.prices.forEach(price => {
                if(price.validity.quantity == product.warranty_period){
                  //Se suman los precios correspondientes a cada plan
                  this.priceTotal = this.priceTotal + price.price
                  //Se almacena el precio seleccionado
                  this.prices.push(price.price)
                  //Se almacena el type del plan
                  this.type_plan.push(filters_product.type)
                }
            });
          });
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
div.ventas {
  padding: 1rem;
}
</style>