<template>
  <section>
    <h3>{{title}}</h3>
    <table v-if="type == 'pet'">
      <tr style="background:#FE195E">

        <!-- Datos de mascota -->
        <th>Mascota</th>
        <th>Tipo</th>
        <th>Género</th>

        <!-- Datos de insurance -->
        <th>Fecha</th>
        <th>Valor</th>
      </tr>
      <tr v-for="(items, index) in displayInfo" :key="index">

        <!-- Datos de mascota -->
        <td v-if="items[0]">{{items[0].name}}</td>
        <td v-if="!items[0]"></td>
        <td v-if="items[0] && items[0].species == 'cat'">gato</td>
        <td v-if="items[0] && items[0].species == 'dog'">perro</td>
        <td v-if="!items[0]"></td>
        <td v-if="items[0] && items[0].sex == 'female'">femenino</td>
        <td v-if="items[0] && items[0].sex == 'male'">masculino</td>
        <td v-if="!items[0]"></td>

        <!-- Datos de insurance -->
        <td v-if="items[1].createdAt">{{items[1].createdAt.slice(0, 10)}}</td>
        <td>{{items[2]}} UF</td>
      </tr>
    </table>

    <table v-else>
      <tr>

        <!-- Datos de persona -->
        <th>Rut</th>
        <th>Nombre</th>
        <th>Teléfono</th>
        <th>Email</th>

        <!-- Datos de insurance -->
        <th>Fecha</th>
        <th>Valor</th>
      </tr>
      <tr v-for="(items, index) in displayInfo" :key="index">

        <!-- Datos de persona -->
        <td v-if="items[3] != null">{{items[3].rut}}</td>
        <td v-if="items[3] == null"></td>
        <td v-if="items[3] != null">{{items[3].name}}</td>
        <td v-if="items[3] == null"></td>
        <td v-if="items[3] != null">{{items[3].phoneNumber}}</td>
        <td v-if="items[3] == null"></td>
        <td v-if="items[3] != null">{{items[3].email}}</td>
        <td v-if="items[3] == null"></td>

        <!-- Datos de insurance -->
        <td v-if="items[1].createdAt">{{items[1].createdAt.slice(0, 10)}}</td>
        <td>{{items[2]}} UF</td>
      </tr>
    </table>

    <br>
    <br>

    <div class="text-center">
      <button 
      type="button"
      v-if="page != 1"
      @click="page--"
      class="btn btn-sm btn-outline-secundary"
      >
        <v-icon
          dark
          left
        >
          mdi-arrow-left
        </v-icon>
      </button>

      <button
      type="button"
      v-for="pageNumber in pages.slice(page, page+5)"
      :key="pageNumber"
      @click="page = pageNumber"
      class="btn btn-sm btn-outline-secundary"
      >
        {{pageNumber}}
      </button>

      <button
      type="button"
      v-if="page<pages.length"
      @click="page++"
      class="btn btn-sm btn-outline-secundary"
      >
        <v-icon
          dark
          left
        >
          mdi-arrow-right
        </v-icon>
      </button>
    </div>
    
  </section>
</template>
<script>
export default {
  name: "VentasTable",
  props:{
    title:{type:String},
    insurances:{type:Array},
    prices:{type:Array},
    people:{type:Array},
    pets:{type:Array},
    type:{type:String},
  },
  data:()=>({
    page: 1,
    perPage: 15,
    pages: []
  }),
  mounted() {
    this.setItems()
  },
  methods: {
    next(data){
      this.$emit('next', data)
    },
    prev(){
      this.$emit('prev')
    },
    paginate(items){
      let page = this.page
      let perPage = this.perPage
      let from = (page * perPage) - perPage
      let to = (page * perPage)
      return items.slice(from, to)

    },
    setItems(){
      let numberOfPages = Math.ceil(this.insurances.length / this.perPage)
      for (let index = 0; index <= numberOfPages; index++) {
        this.pages.push(index)   
      }
    },
  },
  computed: {
    displayInfo(){
      var array = []
      for (let index = 0; index < this.insurances.length; index++) {
        array.push([this.pets[index], this.insurances[index], this.prices[index], this.people[index]])      
      }
      return this.paginate(array)
    }
  },
};
</script>
<style lang="scss" scoped>
section {
    margin-top: 1rem;
    h3{
      color: rgba(0, 0, 0, 0.692);
      text-align: center;
    }
    table {
      background: #f54077;
      border-radius: 30px;
      width: 100%;
      margin: auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      tr{
        display: flex;
        justify-content: space-around;
        padding: 0.5rem 0rem;
        border-bottom: 1px solid gray;
        transition: background .1s;
        position: relative;
        z-index: 1;
        &:last-child{
            border: none;
        }
        &:first-child::after{
            background: transparent;
        }
        &:first-child:hover{
            cursor: default;
        }
        &::after{
            content: '';
            position: absolute;
            z-index: -1;
            left: -20px;
            right: -20px;
            height: 100%;
            background: #fa4b7f;
            transform: scaleX(0);
            opacity: 0;
            transition: transform .5s ease-in-out, opacity 1s;
            transform-origin: right;
            margin-top: -.5rem;
            border-radius: 20px;
        }
        &:hover::after{
            transform-origin: left;
            opacity: 1;
            transform: scaleX(1.2);
        }
        &:hover{
            cursor: pointer;
        }
        th, td{
            width: 100%;
            color:rgb(255, 255, 255)
        }
      }
    }
  }
</style>