<template>
  <div class="ventas">
    <h1 style="color: #FE195E">Mis Ventas</h1>

      <section>

          <template>
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="#FE195E"
                dark
                centered
              >
                <v-tab
                  v-for="item in items"
                  :key="item.tab"
                  :class="{}"
                >
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="item in items"
                  :key="item.tab"
                >
                  <v-card flat>
                    <v-card-text style="background: #f897b4; min-height: 700px;" v-if="item.tab == 'Ventas Totales'">
                      <Table_all :prices="priceTotal" :cantidad="cantidad"></Table_all>
                    </v-card-text>
                    <v-card-text style="background: #f897b4; min-height: 700px;" v-if="item.tab == 'Datos Seguro'">
                      <!-- <Table_insurance v-for="product in products" :key="product._id" :products="product" :prices="prices" :types="type_plan"></Table_insurance> -->
                      <Table_insurance v-for="insurance in insurances" :key="insurance._id" :insurances="insurance" :prices="prices" :people="people" :pets="pets" :type="type_pet"></Table_insurance>
                    </v-card-text>
                    <v-card-text style="background: #f897b4; min-height: 700px;" v-if="item.tab == 'Datos Persona'">
                      <Table_people :people="people"></Table_people>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </template>

      </section>
  </div>
</template>

<script>
import Table_insurance from '@/components/ventasTotales/Table-insurances.vue';
import Table_people from '@/components/ventasTotales/Table-people.vue';
import Table_all from '@/components/ventasTotales/Table-all.vue';
import salesService from '@/services/ventas.service'

export default {
  name: "Ventas",
  data:()=>({
    aux: [],
    cantidad: 0,
    insurances: [],
    pets: [],
    people: [],
    plans: [],
    prices: [],
    priceTotal: 0,
    type_pet: process.env.VUE_APP_PET,
    title: '',
    tab: null,
    items: [
        { tab: 'Ventas Totales'},
        { tab: 'Datos Persona'},
        { tab: 'Datos Seguro'},
    ],
  }),
  components: {
    Table_all,
    Table_people,
    Table_insurance,
  },
  mounted() {
    this.getPlans();
  },
  methods:{
    //Funcion para traer planes del Sponsor
    async getPlans(){
      //Se realiza el llamado al servicio para traer plans
      this.plans = await salesService.getPlans().then((response) => {
        //Una vez obtenida la respuesta, se recorren los datos
        response.data.forEach(plan => {
            //Se establecen filtros para la busqueda de las insurances
            var filters_insurance ={
              id: plan._id,
              price: plan.values[0].price
            }
            //Se llama a la función que llama al servicio que trae las insurances
            this.getInsurances(filters_insurance)
        });
      }) 
      //Se almacena la información final del auxiliar creado en el servicio que trae las insurances
      this.insurances.push(this.aux)
    },
    //Funcion para traer insurances
    async getInsurances(filters_insurance){
      //Se realiza el llamado al servicio para traer insurances
      await salesService.getInsurances(filters_insurance).then((response) => {
        //Una vez obtenida la respuesta, se recorren los datos que no estén vacios
        if(response.data.length != 0){
          response.data.forEach(insurance => {
            //Se almacena cada insurance en un auxiliar
            this.aux.push(insurance)
            //Se almacena el precio del plan relacionado en un arreglo general
            this.prices.push(filters_insurance.price)
            //Se cuentan la cantidad de insurances
            this.cantidad = this.cantidad + 1
            //Se suman los precios correspondientes a cada plan
            this.priceTotal = this.priceTotal + filters_insurance.price
            //Se establecen filtros para la busqueda de la mascota
            var filters_pet = {
              id: insurance.pet
            }
            if(process.env.VUE_APP_PET == "pet"){
              //Se llama a la función que busca a la persona realcionada a la insurance
              this.getPet(filters_pet)
            }
            //Se establecen filtros para la busqueda de la persona
            var filters_person = {
              id: insurance.person
            }
            //Se llama a la función que busca a la persona realcionada a la insurance
            this.getPeople(filters_person)
          });
        }
      })
    },
    //Funcion para traer insurances
    async getPeople(filters_person){
      //Se realiza el llamado al servicio para traer persons
      await salesService.getPeople(filters_person).then((person) => {
        //Se almacena cada persona obtenida
        this.people.push(person)
      })
    },
    async getPet(filters_pet){
      //Se realiza el llamado al servicio para traer pets
      await salesService.getPet(filters_pet).then((pet) => {
        //Se almacena cada persona obtenida
        this.pets.push(pet)
      })
    },
  }
};
</script>

<style lang="scss" scoped>
div.ventas {
  padding: 1rem;
}
</style>