<template>
<section class="section_1">
    <div align="center">
        <!-- <h2>Bienvenidos</h2>
        <br>
        <h1>Amelie 2.0 Powered By</h1>
        <v-img
            width="500"
            src="@/assets/images/embed.png"
        ></v-img>
        <p>Version 0.0.1</p>
        <br>
        <br>  -->
        <Login @login-success="test"/>
        
    </div>
</section>

</template>

    
<script>

import Login from "@/components/Login.vue";

import {Bus} from '../main.js'

export default {
  name: "Home",
  props: ["userData"],
  components: {
    Login,

  },
  data() {
    return {
      success: null,
    };
  },
  methods: {
    test(data){
      // console.log(data);
      Bus.var = data
      Bus.$emit('login', data);
      this.$router.push('dashboard')

    }
  },
  mounted() {
    const query = this.$route.query;
    if (query) {
      if (query.success === "true") {
        this.success = true;
      } else if (query.success === "false") {
        this.success = false;
      }
    }
  },
};
</script>

