import axios from "axios";
const { VUE_APP_VAULTPERK_URL, VUE_APP_SOLAIRE_URL } = process.env;

export default class solaireService {

  static async getCarritos() {
    try { 

        //Llamada al controlador oncologico en V-P, para la busqueda de planes garantia
        const solairetRes = await axios.post(
            `${VUE_APP_SOLAIRE_URL}/amelie/getCarritos`,
        );

        return solairetRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los carritos",
      };
    }
  }

  static async getCarritosSponsor() {
    try { 

        var filters_carritos_sponsor = {
          sponsor: process.env.VUE_APP_SPONSOR
        }

        //Llamada al controlador oncologico en V-P, para la busqueda de planes garantia
        const solairetRes = await axios.post(
            `${VUE_APP_SOLAIRE_URL}/amelie/getCarritosSponsor`, filters_carritos_sponsor
        );

        return solairetRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los carritos",
      };
    }
  }

  static async getPlanId(filters_planId) {
    try { 

        //Llamada al controlador oncologico en V-P, para la busqueda de planes garantia
        const solairetRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getPlanId`, filters_planId
        );

        return solairetRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los carritos",
      };
    }
  }

}