<template>
    <v-data-table :headers="headers" :items="desserts" sort-by="rol" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>User CRUD</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                            Crear Usuario
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container v-if="editedIndex == -1">
                                <div class="ventas">
                                    <div>
                                        <v-form class="crud-form" ref="form">

                                            <v-col>
                                                <v-text-field v-model="userData.name" :rules="nameRules"
                                                    label="Ingrese el nombre" required filled flat solo
                                                    background-color="#eef3f8" hide-details="auto">
                                                    <v-icon slot="prepend"> mdi-account </v-icon>
                                                </v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-select label="Tipo de usuario" v-model="userData.rol"
                                                    :items="['aseguradora', 'sponsor']" :rules="selectRules"
                                                    background-color="#eef3f8" required filled flat solo
                                                    hide-details="auto">
                                                    <v-icon slot="prepend"> mdi-comment-processing</v-icon>
                                                </v-select>
                                            </v-col>

                                            <v-col>
                                                <v-text-field v-model="userData.sponsors[0].sponsor"
                                                    :rules="sponsorRules" label="Ingrese el sponsor" required filled
                                                    flat solo background-color="#eef3f8" hide-details="auto">
                                                    <v-icon slot="prepend"> mdi-reorder-horizontal </v-icon>
                                                </v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-text-field v-model="userData.email" :rules="emailRules"
                                                    label="Ingrese el email" required filled flat solo
                                                    background-color="#eef3f8" hide-details="auto">
                                                    <v-icon slot="prepend"> mdi-at </v-icon>
                                                </v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-text-field v-model="userData.password" :rules="passwordRules"
                                                    label="Ingrese la contraseña" required filled flat solo
                                                    background-color="#eef3f8" hide-details="auto">
                                                    <v-icon slot="prepend"> mdi-account-key </v-icon>
                                                </v-text-field>
                                            </v-col>

                                            <v-col v-if="userData.rol == 'aseguradora'">
                                                <v-text-field label="Cantidad de sponsors asociados"
                                                    v-model="cantidadSponsors" @change="addSponsors()"
                                                    background-color="#eef3f8" required filled flat solo
                                                    hide-details="auto">
                                                    <v-icon slot="prepend"> mdi-account-multiple-plus</v-icon>
                                                </v-text-field>
                                            </v-col>

                                            <template v-if="cantidadSponsors != 0 && userData.rol == 'aseguradora'">
                                                <v-card v-for="(sponsor, index) in sponsors" :key="index">
                                                    <v-card flat>
                                                        <v-card-text style="background: #FE195E; ">
                                                            <Table_users :sponsor="sponsor" :index="index">
                                                            </Table_users>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card>
                                            </template>

                                        </v-form>

                                    </div>
                                </div>
                            </v-container>

                            <v-container v-else>
                                <v-col>
                                    <v-text-field disabled v-model="editedItem.name" label="Nombre"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field disabled v-model="editedItem.rol" label="Rol"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                                </v-col>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="white" text @click="close">
                                Cancelar
                            </v-btn>
                            <v-btn color="white" text @click="save">
                                Guardar cambios
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Estás seguro de eliminar este usuario?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Cargar Datos
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import RutUtils from "../utils/rut.utils";
import ShieldService from '../services/shield.service.example.js';
import Table_users from '@/components/users/Table-users.vue';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
    name: 'CRUD',
    components: {
        Table_users,
    },
    data: () => ({
        //new create user
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: 'Nombre',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Rol', value: 'rol' },
            { text: 'Email', value: 'email' },
            // { text: 'Sponsor', value: 'sponsor' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            name: '',
            rol: '',
            email: '',
            sponsor: '',
            id: '',
        },
        defaultItem: {
            name: '',
            rol: '',
            email: '',
            sponsor: '',
            id: '',
        },

        //antiguo
        newRut: '',
        cantidadSponsors: 0,
        sponsors: [],
        userData: {
            email: '',
            name: '',
            rol: '',
            sponsors: [
                {
                    sponsor: '',
                }
            ],
            password: '',
        },
        nameRules: [(v) => !!v || "El nombre es requerido"],
        sponsorRules: [(v) => !!v || "El sponsor es requerido"],
        surnameRules: [(v) => !!v || "El apellido es requerido"],
        passwordRules: [(v) => !!v || "La contraseña es requerida"],
        rutRules: [
            (v) => !!v || "El RUT es requerido",
            (v) => RutUtils.validateRut(v) || "Rut no valido",
        ],
        serialNumberRules: [
            (v) => !!v || "El numero de serie es requerido",
            (v) => (!!v && v.length > 8) || "Ingrese número de serie correctamente",
        ],
        addressRules: [(v) => !!v || "Debe ingresar su dirección"],
        emailRules: [
            (v) => !!v || "El correo electrónico es requerido",
            (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
        ],
        phoneRules: [
            (v) => !!v || "El número telefónico es requerido",
            (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
            (v) => (!!v && v.length == 9) || "Ingrese un número válido",
        ],
        selectRules: [
            (v) => !!v || "Selecciona una opción válida",
            (v) => v !== 'Seleccionar' || "Selecciona una opción válida",
        ],
    }),
    methods: {
        // async validate(){
        //     if(this.$refs.form.validate()) {
        //         await ShieldService.createUser(this.userData, this.sponsors)
        //     }
        // },
        changeRut(rut) {
            this.userData.rut = RutUtils.cleanRut(rut);
            this.newRut = RutUtils.formatRut(rut);
        },
        addSponsors() {
            this.items = []
            this.sponsors = []
            for (let index = 1; index <= this.cantidadSponsors; index++) {
                var sponsor = {
                    email: '',
                    name: '',
                    // rol: 'sponsor',
                    sponsors: [
                        {
                            sponsor: '',
                        }
                    ],
                    password: '',
                }
                this.sponsors.push(sponsor)

            }
        },
        async initialize() {
            var aux
            this.desserts = []
            await ShieldService.getAll().then((users) => {
                // console.log(users.data)
                users.data.forEach(user => {
                    if (user.role == 'sponsor') {
                        // console.log("sponsor: ", user)
                        aux = {
                            name: user.name,
                            rol: user.role,
                            email: user.email,
                            // sponsor: user.sponsors[0].sponsor,
                            id: user._id
                        }
                        this.desserts.push(aux)
                    } else if (user.role == 'aseguradora') {
                        // console.log("aseguradora: ", user)
                        aux = {
                            name: user.name,
                            rol: user.role,
                            email: user.email,
                            // sponsor: user.sponsors[0].sponsor,
                            id: user._id
                        }
                        this.desserts.push(aux)
                    } else if (user.role == 'admin') {
                        // console.log("admin: ", user)
                        aux = {
                            name: user.name,
                            rol: user.role,
                            email: user.email,
                            // sponsor: 'none',
                            id: user._id
                        }
                        this.desserts.push(aux)
                    }
                });
            })
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            await ShieldService.deleteUser(this.desserts[this.editedIndex].id, this.desserts[this.editedIndex].email).then((response) => {
                if (response.data.status == "ok") {
                    this.desserts.splice(this.editedIndex, 1)
                }
                this.closeDelete()
            })
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async save() {
            // reCaptcha v3 token
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('createUser')
            console.log("Token generado antes de la petición: ", token)

            if (this.editedIndex > -1) {
                await ShieldService.updateUser(this.editedItem)
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
                if (this.$refs.form.validate()) {
                    // Aquí enviamos el token al servicio.
                    await ShieldService.createUser(this.userData, this.sponsors, token).then((response) => {
                        if (response.data.status == 'ok') {
                            this.initialize()
                        } else {
                            window.alert("No se ha creado el usuario")
                        }
                    })
                }
            }
            this.close()
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        // this.initialize()
    },
}
</script>

<style lang="scss" scoped>
div.ventas {
    padding: 1rem;
}

div {
    .crud-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .25rem;

        select,
        input {
            background: white;
            width: 400px;
        }

        div {
            width: 400px;
        }
    }

    button {
        margin: auto;
        display: inherit;
        margin-top: 10px;
    }
}
</style>