<template>
  <section>

    <v-form ref="form" v-model="valid">
      <v-container style="justify-content: center; display: flex; flex-direction: column;">
        <div v-if="loadData == false" class="form-container">
          <v-col style="max-width: 450px; margin: auto;">
            <v-col>
              <v-text-field v-model="consulta" :rules="patenteRules" label="Ej: ABDC12" required solo @change="Mayus()">
                <v-icon slot="prepend"> mdi-barcode </v-icon>
              </v-text-field>
            </v-col>

          </v-col>
        </div>
        <v-row style="justify-content: center; margin-top: 1rem">
          <v-btn v-if="loadData == false" color="primary" @click="consultar()"> Consultar patente </v-btn>
          <v-btn v-if="loadData == true" color="primary" @click="reloadData()">¿Consultar nueva patente? </v-btn>
        </v-row>
      </v-container>
    </v-form>

    <br>
    <br>
    <br>

    <table v-if="loadData" id="cars" class="table table-striped table-bordered display responsive nowrap select"
      style="width:100%">
      <thead>
        <tr>
          <th class="text-left">Descargar</th>
          <th class="text-left">Nombre corredor</th>
          <th class="text-left">Rut corredor</th>
          <th class="text-left">Corredor DV</th>
          <th class="text-left">Nombre empresa</th>
          <th class="text-left">Rut empresa</th>
          <th class="text-left">Empresa DV</th>
          <th class="text-left">Sucursal</th>
          <th class="text-left">Nombre campaña</th>
          <th class="text-left">N° Póliza</th>
          <th class="text-left">N° Folio</th>
          <th class="text-left">Fecha Emisión</th>
          <th class="text-left">Inicio vigencia</th>
          <th class="text-left">Fin vigencia</th>
          <th class="text-left">Nombre pagador</th>
          <th class="text-left">Rut pagador</th>
          <th class="text-left">Pagador DV</th>
          <th class="text-left">Rut propietario</th>
          <th class="text-left">Propietario DV</th>
          <th class="text-left">Nombre propietario</th>
          <th class="text-left">Apellido paterno propietario</th>
          <th class="text-left">Apellido materno propietario</th>
          <th class="text-left">Email propietario</th>
          <th class="text-left">Teléfono propietario</th>
          <th class="text-left">Patente</th>
          <th class="text-left">Patente DV</th>
          <th class="text-left">Año vehículo</th>
          <th class="text-left">N° motor</th>
          <th class="text-left">Marca vehículo</th>
          <th class="text-left">Modelo vehículo</th>
          <th class="text-left">Tipo vehículo</th>
          <th class="text-left">Forma pago</th>
          <th class="text-left">Prima venta ($)</th>
          <th class="text-left">Remarque ($)</th>
          <th class="text-left">Puntos Cencosud</th>
          <th class="text-left">Estado póliza</th>
          <th class="text-left">Enviada correo</th>
          <th class="text-left">Informada AACH</th>
          <th class="text-left">Dígitos tarjeta</th>
          <th class="text-left">Fecha Pago</th>
          <th class="text-left">Fecha generación de pago</th>
          <th class="text-left">Fecha acutalización de pago</th>
          <th class="text-left">Código autorización Transbank</th>
          <th class="text-left">Link de descargar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in desserts" :key="index">
          <td><v-icon style="margin-left: 20%; max-width: 10px; max-height: 10px;" small class="mr-2"
              @click="DescargarArchivo(item.pdf, item.Patente)">
              mdi-download
            </v-icon></td>
          <td>{{ item.Nombre_corredor }}</td>
          <td>{{ item.Rut_corredor }}</td>
          <td>{{ item.Corredor_DV }}</td>
          <td>{{ item.Nombre_empresa }}</td>
          <td>{{ item.Rut_empresa }}</td>
          <td>{{ item.Empresa_DV }}</td>
          <td>{{ item.Sucursal }}</td>
          <td>{{ item.Nombre_campaña }}</td>
          <td>{{ item.NPóliza }}</td>
          <td>{{ item.NFolio }}</td>
          <td>{{ item.Fecha_Emisión }}</td>
          <td>{{ item.Inicio_vigencia }}</td>
          <td>{{ item.Fin_vigencia }}</td>
          <td>{{ item.Nombre_pagador }}</td>
          <td>{{ item.Rut_pagador }}</td>
          <td>{{ item.Pagador_DV }}</td>
          <td>{{ item.Rut_propietario }}</td>
          <td>{{ item.Propietario_DV }}</td>
          <td>{{ item.Nombre_propietario }}</td>
          <td>{{ item.Apellido_paterno_propietario }}</td>
          <td>{{ item.Apellido_materno_propietario }}</td>
          <td>{{ item.Email_propietario }}</td>
          <td>+569 {{ item.Teléfono_propietario }}</td>
          <td>{{ item.Patente }}</td>
          <td>{{ item.Patente_DV }}</td>
          <td>{{ item.Año_vehículo }}</td>
          <td>{{ item.NMotor }}</td>
          <td>{{ item.Marca_vehículo }}</td>
          <td>{{ item.Modelo_vehículo }}</td>
          <td>{{ item.Tipo_vehículo }}</td>
          <td>{{ item.Forma_pago }}</td>
          <td>{{ item.Prima_venta }}</td>
          <td>{{ item.Remarque }}</td>
          <td>{{ item.puntosCencosud }}</td>
          <td>{{ item.Estado_póliza }}</td>
          <td>{{ item.Enviada_correo }}</td>
          <td>{{ item.Informada_AACH }}</td>
          <td>{{ item.Card_digit }}</td>
          <td>{{ item.Fecha_Transaction }}</td>
          <td>{{ item.Fecha_pago }}</td>
          <td>{{ item.Fecha_pago_update }}</td>
          <td>{{ item.authorization_code }}</td>
          <td> <a style="color:blue" :href="item.link" target="_blank">{{ item.link }}</a></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="text-left">Descargar</th>
          <th class="text-left">Nombre corredor</th>
          <th class="text-left">Rut corredor</th>
          <th class="text-left">Corredor DV</th>
          <th class="text-left">Nombre empresa</th>
          <th class="text-left">Rut empresa</th>
          <th class="text-left">Empresa DV</th>
          <th class="text-left">Sucursal</th>
          <th class="text-left">Nombre campaña</th>
          <th class="text-left">N° Póliza</th>
          <th class="text-left">N° Folio</th>
          <th class="text-left">Fecha Emisión</th>
          <th class="text-left">Inicio vigencia</th>
          <th class="text-left">Fin vigencia</th>
          <th class="text-left">Nombre pagador</th>
          <th class="text-left">Rut pagador</th>
          <th class="text-left">Pagador DV</th>
          <th class="text-left">Rut propietario</th>
          <th class="text-left">Propietario DV</th>
          <th class="text-left">Nombre propietario</th>
          <th class="text-left">Apellido paterno propietario</th>
          <th class="text-left">Apellido materno propietario</th>
          <th class="text-left">Email propietario</th>
          <th class="text-left">Teléfono propietario</th>
          <th class="text-left">Patente</th>
          <th class="text-left">Patente DV</th>
          <th class="text-left">Año vehículo</th>
          <th class="text-left">N° motor</th>
          <th class="text-left">Marca vehículo</th>
          <th class="text-left">Modelo vehículo</th>
          <th class="text-left">Tipo vehículo</th>
          <th class="text-left">Forma pago</th>
          <th class="text-left">Prima venta ($)</th>
          <th class="text-left">Remarque ($)</th>
          <th class="text-left">Puntos Cencosud</th>
          <th class="text-left">Estado póliza</th>
          <th class="text-left">Enviada correo</th>
          <th class="text-left">Informada AACH</th>
          <th class="text-left">Dígitos tarjeta</th>
          <th class="text-left">Fecha Pago</th>
          <th class="text-left">Fecha generación de pago</th>
          <th class="text-left">Fecha acutalización de pago</th>
          <th class="text-left">Código autorización Transbank</th>
          <th class="text-left">Link de descargar</th>
        </tr>
      </tfoot>
    </table>

  </section>
</template>

<script>
import consultoraService from '@/services/consultora.service';
import swal from 'sweetalert';

export default {
  name: "CarsTable",
  props: [],
  data: () => ({
    desserts: [],
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    consulta: null,
    cars: null,
    loadData: false,
    patenteRules: [
      (v) => !!v || "El número de patente es requerido",
      (v) => ((!!v && v.length == 5) || (!!v && v.length == 6)) || "Ingrese una patente válida",
    ],
  }),
  async mounted() {
  },
  methods: {
    reloadData() {
      location.reload();
    },
    async consultar() {
      if (this.$refs.form.validate()) {
        var largoPatente = this.consulta.length
        if (largoPatente != 6) {
          var newPatente = this.consulta.match(/[a-z]+|[^a-z]+/gi)
          this.consulta = newPatente[0] + '0' + newPatente[1]
        }
        this.loadData = false
        var consultaRes = await consultoraService.consultaCar(this.consulta)
        if (consultaRes.status == 'ok') {
          this.cars = consultaRes.data
          this.initialize()
        } else {
          swal({
            title: "El SOAP no se encuentra aprobado o no existe en los registros!",
            icon: "info",
            buttons: "ok",
          })
          this.loadData = false
        }
      }
    },
    Mayus() {
      this.consulta = this.consulta.toUpperCase()
    },
    initialize() {
      var aux;
      this.cars.forEach((car) => {
        console.log(car)
        aux = {
          Nombre_corredor: car[2]?.Nombre_corredor ? car[2].Nombre_corredor : '-',
          Rut_corredor: car[2]?.Rut_corredor ? car[2].Rut_corredor : '-',
          Corredor_DV: car[2]?.Corredor_DV ? car[2].Corredor_DV : '-',
          Nombre_empresa: car[2]?.Nombre_empresa ? car[2].Nombre_empresa : '-',
          Rut_empresa: car[2]?.Rut_empresa ? car[2].Rut_empresa : '-',
          Empresa_DV: car[2]?.Empresa_DV ? car[2].Empresa_DV : '-',
          Sucursal: car[2]?.Sucursal ? car[2].Sucursal : '-',
          Nombre_campaña: car[0]?.campana ? car[0].campana : '-',
          NPóliza: car[0]?.numeroPoliza ? car[0].numeroPoliza : '-',
          NFolio: car[0]?.numeroFolio ? car[0].numeroFolio : '-',
          Fecha_Emisión: car[0]?.createdAt.slice(0, 10) ? car[0].createdAt.slice(0, 10) : '-',
          Fecha_Transaction: car[1]?.transaction_date ? car[1]?.transaction_date.slice(0, 10) + ' / ' + car[1].transaction_date?.slice(11, 19) : '-',
          Fecha_pago: car[1]?.createdAt ? car[1].createdAt.slice(0, 10) + ' / ' + car[1].createdAt?.slice(11, 19) : '-',
          Fecha_pago_update: car[1]?.updatedAt ? car[1].updatedAt.slice(0, 10) + ' / ' + car[1].updatedAt.slice(11, 19) : '-',
          Inicio_vigencia: car[2]?.Inicio_vigencia ? car[2].Inicio_vigencia : '-',
          Fin_vigencia: car[2]?.Fin_vigencia ? car[2].Fin_vigencia : '-',
          Nombre_pagador: '-',
          Rut_pagador: car[0]?.rutPagador ? car[0].rutPagador.slice(0, car[0].rutPagador.length - 2) : '-',
          Pagador_DV: car[0]?.rutPagador ? car[0].rutPagador[car[0].rutPagador.length - 1] : '-',
          Rut_propietario: car[0]?.personRut ? car[0].personRut.slice(0, car[0].personRut.length - 2) : '-',
          Propietario_DV: car[0]?.personRut ? car[0].personRut[car[0].personRut.length - 1] : '-',
          Nombre_propietario: car[0]?.personNames ? car[0].personNames : '-',
          Apellido_paterno_propietario: car[0]?.personFirstName ? car[0].personFirstName : '-',
          Apellido_materno_propietario: car[0]?.personSecondName ? car[0].personSecondName : '-',
          Email_propietario: car[0]?.personEmail ? car[0].personEmail : '-',
          Teléfono_propietario: car[0]?.personPhoneNumber ? car[0].personPhoneNumber : '-',
          Patente: car[0]?.patenteNumber ? car[0].patenteNumber : '-',
          Patente_DV: car[0]?.patenteDV ? car[0].patenteDV : '-',
          Año_vehículo: car[0]?.yearVehicle ? car[0].yearVehicle : '-',
          NMotor: car[0]?.numberMotor ? car[0]?.numberMotor : '-',
          Marca_vehículo: car[0]?.marcaVehicle ? car[0].marcaVehicle : '-',
          Modelo_vehículo: car[0]?.modelVehicle ? car[0].modelVehicle : '-',
          Tipo_vehículo: car[0]?.typeVehicle ? car[0].typeVehicle : '-',
          Forma_pago: 'webPay', //si tiene transaction es webpay, si no puntos cencosud
          Prima_venta: car[0]?.price ? car[0].price : '-',
          Remarque: car[0]?.remarque ? car[0].remarque : '-',
          Estado_póliza: car[0]?.status ? car[0].status : '-',
          Enviada_correo: car[2]?.Enviada_correo ? car[2].Enviada_correo : '-',
          Informada_AACH: car[2]?.Informada_AACH ? car[2].Informada_AACH : '-',
          Card_digit: car[1] ? car[1].card_detail?.card_number : '-',
          pdf: car[0]?.pdf ? car[0].pdf : '-',
          puntosCencosud: car[0]?.pricePuntos ? car[0].pricePuntos : '-',
          link: car[0]?.numeroPoliza ? process.env.VUE_APP_SITE_DOMAIN + 'docs?id=' + car[0].numeroPoliza : '-',
          authorization_code: car[1] ? car[1].authorization_code : '-',
        }
        this.desserts.push(aux)

      });
      this.loadData = true
      this.table()
    },
    DescargarArchivo(pdf, patente) {
      const url = window.URL.createObjectURL(new Blob([new Uint8Array(pdf.data).buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `vehiculo-${patente}.pdf`);
      document.body.appendChild(link);
      console.log(link)
      link.click();
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    table() {
      var $ = require('jquery');
      this.$nextTick(() => {
        require('datatables.net-buttons/js/buttons.colVis.js')
        require('datatables.net-responsive-bs4')
        $('#cars').DataTable({
          initComplete: function () {
            this.api()
              .columns()
              .every(function () {
                var column = this;
                var select = $('<select><option value="" selected="selected">Filtrar</option></select>')
                  .appendTo($(column.footer()).empty())
                  .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());


                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                  });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d) {
                    var val = $.fn.dataTable.util.escapeRegex(d);
                    if (column.search() === "^" + val + "$") {
                      select.append(
                        '<option value="' + d + '" selected="selected">' + d + "</option>"
                      );
                    } else {
                      select.append('<option value="' + d + '">' + d + "</option>");
                    }
                  });
              });
          },
          "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
          },
          dom: 'Bfrtip',
          buttons: [
            {
              "extend": "excelHtml5",
              "text": "<i class='fas fa-file-excel'></i> Excel",
              "titleAttr": "Exportar Excel",
              "className": "btn btn-success"
            },
          ]
        })
      })
    },
  }
}
</script>

