<template>
  <div class="ventas">
    <h1 style="color: #FE195E">Leads</h1>
    <section>

          <template>
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="#FE195E"
                dark
                show-arrows
                centered
              >
                <v-tab
                  v-for="item in items"
                  :key="item.tab"
                  :class="{}"
                >
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="item in items"
                  :key="item.tab"
                >
                  <v-card flat>
                    <v-card-text style="background: #f897b4; min-height: 1000px;" v-if="item.tab == 'Carritos Totales'">
                      <Table_all :sponsors="sponsors" :cantidades="cantidades"></Table_all>
                    </v-card-text>
                    <!-- <v-card-text style="background: #f897b4; min-height: 1000px;" v-if="item.tab == 'Datos Formulario'">
                      <Table_cars v-for="carrito in carritos" :key="carrito._id" :carritos="carrito"></Table_cars>
                    </v-card-text> -->
                    <v-card-text style="background: #f897b4; min-height: 1000px;" v-if="item.tab == 'Sponsors'">
                      <Table_people :carritos="carritos" :plans="plans"></Table_people>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </template>

      </section>
    </div>
</template>

<script>
import Table_all from '@/components/carritos/Table-all.vue';
import Table_people from '@/components/carritos/Table-people.vue';
import amelieService from '@/services/carrito.service'

export default {
  name: "Ventas",
  data:()=>({
    title: '',
    plans: [],
    carritos: [],
    sponsors: [],
    cantidades: [],
    tab: null,
    items: [
        { tab: 'Carritos Totales'},
        { tab: 'Sponsors'},
    ],
  }),
  components: {
    Table_all,
    Table_people,
  },
  mounted() {
    this.getCarritosSponsor();
  },
  methods:{
    async getCarritosSponsor(){
      await amelieService.getCarritosSponsor().then((response) => {
        response.data.forEach(carrito => {
          this.getCarritos(carrito)
          this.getPlanId(carrito)
        })
      })
      // this.plans.forEach((plan) => {
      //   console.log(plan)
      // })
    },
    //Funcion para traer carritos
    async getCarritos(carrito){
      if(this.sponsors.includes(carrito.sponsor) == true){
        var fuction = (sponsor) => sponsor == carrito.sponsor
        this.cantidades[this.sponsors.findIndex(fuction)] = this.cantidades[this.sponsors.findIndex(fuction)] + 1
      } else {
        // var tab = {
        //   tab: carrito.sponsor
        // }
        // this.items.push(tab)
        this.sponsors.push(carrito.sponsor)
        this.cantidades.push(1)
      }
    },
    async getPlanId(carrito){
      var filters_planId
      if(carrito.steptwo.length == 0){
        filters_planId = {
          plan: "vacio",
        }
        await amelieService.getPlanId(filters_planId).then((response) => {
          this.plans.push(response.data)
          this.carritos.push(carrito)
        })
      } else {
        filters_planId = {
          plan: carrito.steptwo[0].plan,
        }
        await amelieService.getPlanId(filters_planId).then((response) => {
          this.plans.push(response.data[0])
          this.carritos.push(carrito)
        })
      }    
    },
  }
};
</script>

<style lang="scss" scoped>
div.ventas {
  padding: 1rem;
}
</style>