import amelieService from '@/services/carrito.service'
import infoService from '@/services/getInfoCarritoAseguradora.service'

export default class ShieldService {

    static async getCars() {

        var plans = []
        var carritos = []
        var sponsors = []
        var cantidades = []
        var params = []
        var sponsors_account = localStorage.getItem("sponsorsInsurance")

        sponsors_account = sponsors_account.split('+')

        //Se realiza el llamado al servicio
        await amelieService.getCarritos().then((response) => {
            //Una vez obtenida la respuesta, se recorren los datos
            response.data.forEach(carrito => {
                //Se busca el plan asociado mediante la funcion
                infoService.getPlanId(carrito, plans, carritos, sponsors, cantidades, sponsors_account)
            })
            params = [plans, carritos, sponsors, cantidades]
        })

        return params

    }

    static async getPlanId(carrito, plans, carritos, sponsors, cantidades, sponsors_account) {
        sponsors_account.forEach( async (element) => {
            if (element == carrito.sponsor) {
                var filters_planId
                if (sponsors.includes(carrito.sponsor) == true) {
                    var fuction = (sponsor) => sponsor == carrito.sponsor
                    var indice = sponsors.findIndex(fuction)
                    cantidades[indice] = cantidades[indice] + 1
                } else {
                    sponsors.push(carrito.sponsor)
                    cantidades.push(1)
                }
                if (carrito.steptwo.length == 0) {
                    filters_planId = {
                        plan: "vacio",
                    }
                    await amelieService.getPlanId(filters_planId).then((response) => {
                        plans.push(response.data)
                        carritos.push(carrito)
                    })
                } else {
                    filters_planId = {
                        plan: carrito.steptwo[0].plan,
                    }
                    await amelieService.getPlanId(filters_planId).then((response) => {
                        if (response.data.length == 0) {
                            plans.push(undefined)
                        } else {
                            plans.push(response.data[0])
                        }
                        carritos.push(carrito)
                    })
                }
            }
        })
    }
}