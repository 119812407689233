<template>

  <section>

    <table id="carrito" class="table table-striped table-bordered display responsive nowrap select" style="width:100%">
      <thead>
        <tr>
          <th class="text-left">Sponsor</th>
          <th class="text-left">Fecha</th>
          <th class="text-left">Nombre</th>
          <th class="text-left">Email</th>
          <th class="text-left">Teléfono</th>
          <th class="text-left">Rut</th>
          <th class="text-left">Region</th>
          <th class="text-left">Comuna</th>
          <th class="text-left">Dirección</th>
          <th class="text-left">Nombre Plan</th>
          <th class="text-left">Categoría</th>
          <th class="text-left">Tipo</th>
          <th class="text-left">Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in desserts" :key="index">
          <td>{{ item.sponsor}}</td>
          <td>{{ item.date}}</td>
          <td>{{ item.name}}</td>
          <td>{{ item.email}}</td>
          <td>{{ item.phoneNumber}}</td>
          <td>{{ item.rut}}</td>
          <td>{{ item.region}}</td>
          <td>{{ item.commune}}</td>
          <td>{{ item.address}}</td>
          <td>{{ item.planName}}</td>
          <td>{{ item.category}}</td>
          <td>{{ item.type}}</td>
          <td>{{ item.value}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="text-left">Sponsor</th>
          <th class="text-left">Fecha</th>
          <th class="text-left">Nombre</th>
          <th class="text-left">Email</th>
          <th class="text-left">Teléfono</th>
          <th class="text-left">Rut</th>
          <th class="text-left">Region</th>
          <th class="text-left">Comuna</th>
          <th class="text-left">Dirección</th>
          <th class="text-left">Nombre Plan</th>
          <th class="text-left">Categoría</th>
          <th class="text-left">Tipo</th>
          <th class="text-left">Valor</th>
        </tr>
      </tfoot>
    </table>

  </section>

</template>

<script>

export default {
  name: "CarritoTable",
  props: ["plans", "carritos", "LoadVentas"],
  data: () => ({
    desserts: [],
  }),
  async mounted() {
    if (this.LoadVentas == false) {
      await this.initialize();
    }
  },
  methods: {
    initialize() {
      var aux;
      this.carritos.forEach((carrito, index) => {
        if (carrito.lenght >= 4) {
          if (carrito.lenght == 4) {
            aux = {
              name: carrito.stepOne[0].name == null ? 'x' : carrito.stepOne[0].name,
              email: carrito.stepOne[0].email == null ? 'x' : carrito.stepOne[0].email,
              phoneNumber: carrito.stepOne[0].phoneNumber == null ? 'x' : carrito.stepOne[0].phoneNumber,
              date: carrito.createdAt == null ? 'x' : carrito.createdAt.slice(0, 10),
              rut: '',
              region: '',
              commune: '',
              address: '',
              lenght: carrito.lenght == null ? 'x' : carrito.lenght,
              sponsor: carrito.sponsor == null ? 'x' : carrito.sponsor,
              planName: this.plans[index] == undefined ? 'x' : this.plans[index].name,
              category: this.plans[index] == undefined ? 'x' : this.plans[index].category,
              type: this.plans[index] == undefined ? 'x' : this.plans[index].type,
              value: this.plans[index] == undefined ? 'x' : this.plans[index].price,
            }
            this.desserts.push(aux)
          } else {
            aux = {
              name: carrito.stepOne[0].name == null ? 'x' : carrito.stepOne[0].name,
              email: carrito.stepOne[0].email == null ? 'x' : carrito.stepOne[0].email,
              phoneNumber: carrito.stepOne[0].phoneNumber == null ? 'x' : carrito.stepOne[0].phoneNumber,
              date: carrito.createdAt == null ? 'x' : carrito.createdAt.slice(0, 10),
              rut: carrito.stepthree[0].rut == null ? 'x' : carrito.stepthree[0].rut,
              region: carrito.stepthree[0].region == null ? 'x' : carrito.stepthree[0].region,
              commune: carrito.stepthree[0].commune == null ? 'x' : carrito.stepthree[0].commune,
              address: carrito.stepthree[0].address == null ? 'x' : carrito.stepthree[0].address,
              lenght: carrito.lenght == null ? 'x' : carrito.lenght,
              sponsor: carrito.sponsor == null ? 'x' : carrito.sponsor,
              planName: this.plans[index] == undefined ? 'x' : this.plans[index].name,
              category: this.plans[index] == undefined ? 'x' : this.plans[index].category,
              type: this.plans[index] == undefined ? 'x' : this.plans[index].type,
              value: this.plans[index] == undefined ? 'x' : this.plans[index].price,
            }
            this.desserts.push(aux)
          }
        } else {
          aux = {
            name: carrito.stepOne[0].name == null ? 'x' : carrito.stepOne[0].name,
            email: carrito.stepOne[0].email == null ? 'x' : carrito.stepOne[0].email,
            phoneNumber: carrito.stepOne[0].phoneNumber == null ? 'x' : carrito.stepOne[0].phoneNumber,
            date: carrito.createdAt == null ? 'x' : carrito.createdAt.slice(0, 10),
            rut: '',
            region: '',
            commune: '',
            address: '',
            lenght: carrito.lenght == null ? 'x' : carrito.lenght,
            sponsor: carrito.sponsor == null ? 'x' : carrito.sponsor,
            planName: '',
            category: '',
            type: '',
            value: '',
          }
          this.desserts.push(aux)
        }

      });
      this.table()
    },
    table() {
      var $ = require('jquery');
      this.$nextTick(() => {
        require('datatables.net-buttons/js/buttons.colVis.js')
        require('datatables.net-responsive-bs4')
        $('#carrito').DataTable({
          initComplete: function () {
            this.api()
              .columns()
              .every(function () {
                var column = this;
                var select = $('<select><option value="" selected="selected">Filtrar</option></select>')
                  .appendTo($(column.footer()).empty())
                  .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());


                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                  });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d) {
                    var val = $.fn.dataTable.util.escapeRegex(d);
                    if (column.search() === "^" + val + "$") {
                      select.append(
                        '<option value="' + d + '" selected="selected">' + d + "</option>"
                      );
                    } else {
                      select.append('<option value="' + d + '">' + d + "</option>");
                    }
                  });
              });
          },
          "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
          },
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'colvis',
              "text": "Filtros",
              "titleAttr": "Filtros",
            },
            {
              "extend": "copyHtml5",
              "text": "Copiar",
              "titleAttr": "Copiar",
              "className": "btn btn-secundary"
            },
            {
              "extend": "excelHtml5",
              "text": "<i class='fas fa-file-excel'></i> Excel",
              "titleAttr": "Exportar Excel",
              "className": "btn btn-success"
            },
            {
              "extend": "pdfHtml5",
              "text": "<i class='fas fa-file-pdf'></i> PDF",
              "titleAttr": "Copiar",
              "className": "btn btn-danger"
            },
            {
              "extend": "csvHtml5",
              "text": "<i class='fas fa-file-csv'></i> CSV",
              "titleAttr": "Expotar a CSV",
              "className": "btn btn-info"
            },
          ]
        })
      })
    },
  }
}
</script>

