<template>
    <div class="planes">
      <h1 style="color: black"> Control de Planes</h1>
      <section>
  
        <template>
          <v-card>
            <v-tabs v-model="tab" background-color="#FE195E" dark show-arrows centered>
              <v-tab v-for="item in items" :key="item.tab" :class="{}">
                {{ item.tab }}
              </v-tab>
            </v-tabs>
  
            <div>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in items" :key="item.tab">
                  <v-card flat>
                    <v-card-text style=" min-height: 800px; max-width: 1200px; margin:auto;" v-if="item.tab == 'Crear'">
                        <br>
                        <div class="subida">
                        <v-file-input
                        v-model="file"
                        label="Seleccionar archivo"
                        Underlined
                        accept=".xlsx"
                        placeholder="Click aquí para subir documento"
                        clearable
                        filled
                        hide-details
                        prepend-icon="mdi-file"
                        @change="cargarExcel"
                        ></v-file-input>
                        
                    </div>
                    <v-btn color="primary" class="boton" @click="uploadFile">Enviar</v-btn>
                     <br><br>
                    <div v-if="ShowTabla == true">
                      <table class="my-table">
                        <thead>
                          <tr>
                            <th>N°</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Sponsor</th>
                            <th>Category</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in jsonResponse" :key="index">
                            <td class="my-cell">{{ index + 1 }} </td>
                            <td v-if="item.name !== null" class="my-cell">{{ item.name }}</td>
                            <td v-if="item.type !== null" class="my-cell">{{ item.type }}</td>
                            <td v-if="item.sponsor !== null" class="my-cell">{{ item.sponsor }}</td>
                            <td v-if="item.category !== null" class="my-cell">{{ item.category }}</td>
                            <td v-if="item.price !== null" class="my-cell">{{ item.price }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </v-card-text>
                    <v-card-text style=" min-height: 800px; max-width: 1200px; margin:auto;" v-if="item.tab == 'Editar'">
                      <div style=" margin:auto;display: flex; justify-content: center; align-items: center;">
                      <label style="margin-right: 5px;" for="sponsor">Busqueda por sponsor: </label>
                      <input style="border: 1px solid rgb(145, 141, 141); padding: 5px;" type="text" id="sponsor" v-model="sponsor">
                    </div>
                    <br>
                    
                    <v-btn class="boton" @click="buscar">Buscar</v-btn>
                    <br><br>
                    <div v-if="ShowTablaEditar == true">
                    <table class="my-table">
                      <thead>
                        <tr>
                          <th v-for="field in fields" :key="field">{{ field }}</th>
                          <th>Planes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(plan, index) in plans" :key="index">
                          <td v-for="field in fields" :key="field" class="my-cell">
                            <input v-model="plan[field]">
                          </td>
                          <td class="my-cell">{{ index + 1 }} </td>
                          <td class="my-cell"> {{ plan._id }}</td>
                          <td class="my-cell"> {{ plan.name }}</td>
                          <td class="my-cell"> {{ plan.type }}</td>
                          <td class="my-cell">
                            <v-btn class="boton" @click="editarDocumento(plan)">Editar</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="Seleccionado">
                    <br>
                    <h4 style="text-align: center;">Editar Plan: {{ Seleccionado.name }}</h4>
                    <h4 style="text-align: center;">_id: {{ Seleccionado._id }}</h4>
                    <br>
                    <div v-for="(value, key) in Seleccionado" :key="key" class="input-row">
                      <div v-if="key !== '_id'">
                        <label>{{ key }} :</label>
                        <div v-if="typeof value !== 'object'" class="flex-container">
                          <v-text-field v-model="Seleccionado[key]" solo></v-text-field>
                        </div>
                        <div v-else>
                          <div v-for="(innerValue, innerKey) in value" :key="innerKey">
                            <div v-if="typeof innerValue !== 'object'" class="flex-container" style="padding-bottom: 15px;">
                              <div v-if="innerKey !== '_id'" class="flex-container">
                                <label>{{ innerKey }} :</label>
                                <div v-if="innerKey !== 'amount'" class="flex-container">
                                  <v-text-field  v-model="Seleccionado[key][innerKey]" solo></v-text-field>
                                </div>
                                <div v-else-if="innerKey === 'amount'" class="flex-container">
                                  <div style="margin-right: 10%;">
                                  <v-text-field v-model="Seleccionado[key][innerKey]" solo></v-text-field>
                                  </div>
                                  <v-select v-model="formatoAmount" :items="['string', 'number']" solo ></v-select>
                              </div>
                              </div>
                            </div>
                            <div v-else>
                              <div v-for="(deepValue, deepKey) in innerValue" :key="deepKey">
                                <div v-if="typeof deepValue !== 'object'" style="padding-bottom: 15px;">
                                  <br>
                                  <div v-if="deepKey !== '_id'" class="flex-container">
                                    <label>{{ deepKey }} :</label>
                                    <div v-if="deepKey !== 'amount'" class="flex-container">
                                  <v-text-field  v-model="Seleccionado[key][innerKey][deepKey]" solo></v-text-field>
                                   </div>
                                    <div v-else-if="deepKey === 'amount'" class="flex-container">
                                      <div style="margin-right: 10%;">
                                      <v-text-field v-model="Seleccionado[key][innerKey][deepKey]" solo></v-text-field>
                                    </div>
                                      <v-select v-model="formatoAmount" :items="['string', 'number']" solo></v-select>
                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                  <div v-for="(deepestValue, deepestKey) in deepValue" :key="deepestKey">
                                    <div v-if="typeof deepestValue !== 'object'" class="flex-container" style="padding-bottom: 15px;">
                                      <br>
                                      <div v-if="deepestKey !== '_id'" class="flex-container">
                                        <label >{{ deepestKey }} :</label>
                                        <v-text-field v-model="Seleccionado[key][innerKey][deepKey][deepestKey]" solo></v-text-field>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <v-btn class="boton" @click="guardarCambios">Guardar</v-btn>
                  </div>
                </div>
                    </v-card-text>
                    <v-card-text style=" min-height: 800px; max-width: 1200px; margin:auto;" v-if="item.tab == 'Eliminar'">
                      <div style=" margin:auto;display: flex; justify-content: center; align-items: center;">
                      <label style="margin-right: 5px;" for="sponsor">Busqueda por sponsor: </label>
                      <input style="border: 1px solid rgb(145, 141, 141); padding: 5px;" type="text" id="sponsor" v-model="sponsor">
                    </div>
                    <br>
                    <v-btn class="boton" @click="buscarEliminar">Buscar</v-btn>
                    <br><br>
                    <div  v-if="ShowTablaEliminar == true">
                    <table class="my-table">
                      <thead>
                        <tr>
                          <th v-for="field in fields" :key="field">{{ field }}</th>
                          <th>N°</th>
                          <th>Ids</th>
                          <th>Nombre</th>
                          <th>Tipo</th>
                          <th>Eliminar</th>
                          <th><input type="checkbox" v-model="selectAll" @change="toggleSelectAll"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(plan, index) in plans" :key="index">
                          <td v-for="field in fields" :key="field" class="my-cell">
                            <input v-model="plan[field]">
                          </td>
                          <td class="my-cell">{{ index + 1 }} </td>
                          <td class="my-cell"> {{ plan._id }}</td>
                          <td class="my-cell"> {{ plan.name }}</td>
                          <td class="my-cell"> {{ plan.type }}</td>
                          <td class="my-cell">
                          <v-btn class="boton" @click="eliminarDocumento(plan)">Eliminar</v-btn>
                          </td>
                          <td class="my-cell">
                            <input type="checkbox" :id="'checkbox-' + index" :value="plan._id" v-model="selectedPlans">
                            <label :for="'checkbox-' + index"></label>
                        </td>
                        </tr>
                      </tbody>
                    </table>
                  

                    <br>
                    <v-btn class="boton" @click="eliminarPlanesSeleccionados">Eliminar Seleccionados</v-btn>
                  </div>
                    </v-card-text>
                    <v-card-text style=" min-height: 800px; max-width: 1200px; margin:auto;" v-if="item.tab == 'Lista'">
                      <v-btn class="boton" @click="listaSponsor">Traer Lista</v-btn>
                      <br><br>

                    <div v-if="ShowTablaSponsor == true">
                      <table class="my-table">
                        <thead>
                          <tr>
                            <th>N°</th>
                            <th>Sponsor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in listadoResponse" :key="index">
                            <td class="my-cell">{{ index + 1 }} </td>
                            <td v-if="item.sponsor !== null" class="my-cell">{{ item }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
  
          </v-card>
        </template>
  
      </section>
    </div>
  </template>
  
  
  <script>
  
  import planService from "@/services/plan.service.js";
  import swal from 'sweetalert';

  export default {
    name: "planes",
    props: [],
    data: () => ({
      selectAll: false,
      title: '',
      formatoAmount: {},
      file: null,
      tab: null,
      ShowTabla: false,
      ShowTablaSponsor: false,
      ShowTablaEliminar: false,
      ShowTablaEditar: false,
      jsonResponse: null,
      listadoResponse: null,
      tipos: [
        { value: 'int32', text: 'Int32' },
        { value: 'string', text: 'String' },
        { value: 'int64', text: 'Int64' }
      ],
      selectedPlans: [],
      plans: [],
      fields: [],
      items: [
        { tab: 'Crear' },
        { tab: 'Editar' },
        { tab: 'Eliminar' },
        { tab: 'Lista' },
      ],
      documentos: [], // Agregué la variable "documentos"
      documento: {}, // Agregué la variable "documento"
      Seleccionado: null, // Agregué la variable "Seleccionado"
    }),
    components: {
    },
    mounted() {
    },
    methods: {
      formatearAmounts(objeto) {
    if (objeto.coverage && Array.isArray(objeto.coverage)) {
      for (const item of objeto.coverage) {
        if (item.amount) {
          const originalValue = item.amount;
          // Guardar el valor original antes de aplicar el formato
          console.log("Valor original de amount:", originalValue);
          if (this.formatoAmount === "number") {
            item.amount = Number(originalValue);
          } else {
            item.amount = String(originalValue);
          }
          console.log("Valor de amount después del formato:", item.amount);
        }
      }
    }
},
      async guardarCambios() {
      try {
        const jsonEditado = JSON.parse(JSON.stringify(this.Seleccionado));
        this.formatearAmounts(jsonEditado);
        
        swal({
          title: "¿Estás seguro?",
          text: "Editaras el Plan Seleccionado.",
          icon: "info",
          buttons: true,
          dangerMode: true,
      }).then( async (willDelete) => {
          if (willDelete) {
            const editarPlanes = await planService.editarPlanes(jsonEditado);
            if (editarPlanes.status === '200') {
              swal({
                  title: "Plan editado existosamente!",
                  icon: "success",
                  buttons: "Ok",
                })
                this.documentoSeleccionado = null;
              } else {
                swal({
                  title: "El plan no ha sido Editado.",
                  text: "Tipo de dato incorrecto.",
                  icon: "warning"});
              }
          } else {
              swal({
                  title: "El plan no ha sido Editado",
                  text: "Tipo de dato incorrecto.",
                  icon: "warning"});
          }
      });
      } catch (error) {
        console.error(error);
      }
    },
  async cargarExcel(file) {
  try {
    this.file = file;
    console.log(this.file);
  } catch (error) {
    console.error(error);
  }
},
  async uploadFile() {
    try {
      if (this.file != null) {
        var cargaFile = await planService.cargarPlanes(this.file);
        if (cargaFile.status == '200'){
          this.ShowTabla = true;
          this.jsonResponse = cargaFile.message;
          swal({
            title: "Planes agregados existosamente!",
            icon: "success",
            buttons: "Ok",
          })
        } else {
          this.ShowTabla = false;
          swal({
            title: "Error al crear",
            text: cargaFile.message,
            icon: "error",
            buttons: "Ok",
          })
        }
        // Aquí puedes realizar otras acciones después de cargar el archivo
      } else {
        console.log("No se cargo el archivo");
      }
    } catch (error) {
      console.error(error);
    }
    },
    toggleSelectAll() {
    if (this.selectAll) {
      this.selectedPlans = this.plans.map(plan => plan._id); // Seleccionar todos los planes
    } else {
      this.selectedPlans = []; // Deseleccionar todos los planes
    }
  },
    async buscar() {
    try {
      this.ShowTablaEditar = true;
      console.log('Sponsor:', this.sponsor);
      const traerplanes = await planService.traerPlanes(this.sponsor);
      if (traerplanes.status == '200'){
          this.plans = traerplanes.message;
      }
      if (traerplanes.message.length === 0) {
      swal({
          title: "No existen planes",
          icon: "error",
          buttons: "Ok",
        })
      }
      if (traerplanes.status !== '200'){
        swal({
            title: "No existen planes",
            text: traerplanes.message,
            icon: "error",
            buttons: "Ok",
          })
        }
    } catch (error) {
      console.error(error);
    }
  },
  async buscarEliminar() {
    try {
      this.ShowTablaEliminar = true;
      const traerplanes = await planService.traerPlanes(this.sponsor);
      if (traerplanes.status == '200'){
          this.plans = traerplanes.message;
          console.log(this.plans);
      }
      if (traerplanes.message.length === 0) {
      swal({
          title: "No existen planes",
          icon: "error",
          buttons: "Ok",
        })
      }
      if (traerplanes.status !== '200'){
        swal({
            title: "No existen planes",
            text: traerplanes.message,
            icon: "error",
            buttons: "Ok",
          })
        }
    } catch (error) {
      console.error(error);
    }
  },
    async listaSponsor() {
    try {
      const listadoSponsor = await planService.listadoSponsor();
      if (listadoSponsor.status == '200'){
          this.listadoResponse = listadoSponsor.message;
          this.ShowTablaSponsor = true;
      }
      if (listadoSponsor.message.length === 0) {
      swal({
          title: "No existen planes",
          icon: "error",
          buttons: "Ok",
        })
      }
      if (listadoSponsor.status !== '200'){
        swal({
            title: "No existen planes",
            text: listadoSponsor.message,
            icon: "error",
            buttons: "Ok",
          })
        }
    } catch (error) {
      console.error(error);
    }
  },
    async editarDocumento(plans) {
      this.Seleccionado = plans;
      console.log(this.Seleccionado.name);
    },
    
    async eliminarDocumento(plans) {
      this.Seleccionado = plans;
      console.log(this.Seleccionado._id);
      const id = [this.Seleccionado._id];

      swal({
          title: "¿Estás seguro?",
          text: "Eliminaras el Plan Seleccionado.",
          icon: "info",
          buttons: true,
          dangerMode: true,
      }).then( async (willDelete) => {
          if (willDelete) {
            const eliminarPlanes = await planService.eliminarPlanes(id);
            if (eliminarPlanes.status === '200') {
              console.log("entra a buscar otra vez");
              swal({
                  title: "Plan eliminados existosamente!",
                  icon: "success",
                  buttons: "Ok",
                })
              await this.buscar();
              } else {
                swal({
                  title: "Los planes no han sido Elimnados.",
                  icon: "warning"});
              }
          } else {
              swal({
                  title: "Los planes no han sido Elimnados.",
                  icon: "warning"});
          }
      });
    },
    async eliminarPlanesSeleccionados() {
        try {
        const planesSeleccionados = this.plans.filter(plan => this.selectedPlans.includes(plan._id));
        // Obtener los _id de los planes seleccionados
        this.idsSeleccionados = planesSeleccionados.map(plan => plan._id); 
        swal({
          title: "¿Estás seguro?",
          text: "Eliminaras los Planes seleccionados.",
          icon: "info",
          buttons: true,
          dangerMode: true,
      }).then( async (willDelete) => {
          if (willDelete) {
            const eliminarPlanes = await planService.eliminarPlanes(this.idsSeleccionados);
            if (eliminarPlanes.status === '200') {
              swal({
                  title: "Plan eliminados existosamente!",
                  icon: "success",
                  buttons: "Ok",
                })
              await this.buscar();
              } else {
                swal({
                  title: "Los planes no han sido Elimnados.",
                  icon: "warning"});
              }
          } else {
              swal({
                  title: "Los planes no han sido Elimnados.",
                  icon: "warning"});
          }
      });
      } catch (error) {
        console.error(error);
      }
      },
    },
    
}
  </script>
  
  <style lang="scss" scoped>
  div.planes {
    padding: 1rem;
  }
  div.subida {
    margin: auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;
  }

  .boton {
    margin: auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .my-table {
  width: 100%;
  border-collapse: collapse;
}

.my-cell {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.my-table th {
  background-color: #f2f2f2;
  text-align: center;
}

.my-table tr:nth-child(even) {
  background-color: #e9e9e9;
}
.input-row {
  align-items: center;
  margin-bottom: 10px;
}

.input-row label {
  margin-right: 5px;
  width: 150px;
}

.input-row input {
  border: 1px solid rgb(145, 141, 141);
  flex-grow: 1;
  padding: 5px;
  max-width: 65%;
}

.select {
  margin-left: 2%;
  max-width: 50%;
}

.flex-container {
  display: flex;
  align-items: center;
}

.label-central
{
  padding-bottom: 10px;
}

  </style>