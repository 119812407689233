<template>
  <div class="ventas">
    <h1 style="color: black">Seguros vendidos</h1>

    <section>

      <template>
        <v-card>
          <v-tabs v-model="tab" background-color="#FE195E" dark centered>
            <v-tab v-for="item in items" :key="item.tab" :class="{}">
              {{ item.tab }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item.tab">
              <v-card flat>
                <v-card-text style=" min-height: 800px; max-width: 1200px; margin:auto;" v-if="item.tab == 'Resumen'">
                  <Table_all :sponsors="sponsors" :cantidades="cantidades" :LoadVentas="LoadVentas"></Table_all>
                </v-card-text>
                <v-card-text style=" min-height: 800px; max-width: 1200px; margin:auto;" v-if="item.tab == 'General'">
                  <Table_people :insurances="insurances" :prices="prices" :types="types" :pets="pets" :people="people"
                    :sponsor="sponsor" :LoadVentas="LoadVentas"></Table_people>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>

    </section>

  </div>
</template>

<script>
import Table_all from '@/components/ventasTotales/Table-all-aseguradora.vue';
import Table_people from '@/components/ventasTotales/Table-aseguradora.vue';

export default {
  name: "Ventas",
  props: ["people", "types", "insurances", "prices", "pets", "sponsor", "LoadVentas", "sponsors", "cantidades"],
  data:()=>({
    title: '',
    tab: null,
    items: [
      { tab: 'General' },
      { tab: 'Resumen' },
    ],
  }),
  components: {
    Table_people,
    Table_all,
  },
  mounted() {
  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>
div.ventas {
  padding: 1rem;
}
</style>