import axios from "axios";

const { VUE_APP_SHIELD_URL , VUE_APP_VAULTPERK_URL, VUE_APP_PERK_KEY } = process.env;

export default class pdfService {
    
  static async cargarPlanes(file) {
    try 
    
        {     
            console.log(file);  
            console.log("ArribaExcel");
            const formData = new FormData();
            formData.append('archivoXLSX', file, 'cargaPlanes.xlsx');
        //Llamada al controlador createPlanXlsx de shield
        const cargarExcelRes = await axios.post(
            `${VUE_APP_SHIELD_URL}/plan/createPlanXlsx`, formData,{
            headers : {
                'Content-Type': 'multipart/form-data',
            }
            })
        console.log(cargarExcelRes.data);
        return {
            status: "200", 
            message: cargarExcelRes.data,
        };

        } catch (error) {
            //console.log(error.response.data.detalle.message);
            const message = error.response.data.detalle.message
            return {
                status: "error", 
                message: message,
            };
        }
    }

    static async traerPlanes(sponsor) {
        try 
        
            {     
                console.log(sponsor);  
                console.log("ArribaSponsor");
            const planSponsor = await axios.get(
                `${VUE_APP_VAULTPERK_URL}/plan?sponsor=${sponsor}`,{
                    headers: {
                        perk_key: VUE_APP_PERK_KEY,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                      }
                })
            console.log(planSponsor.data);
            return {
                status: "200", 
                message: planSponsor.data.plans,
            };
    
            } catch (error) {
                //console.log(error.response.data.detalle.message);
                // const message = error.response.data.detalle.message
                return {
                    status: "error", 
                    // message: message,
                };
            }
        }

    static async editarPlanes(planEdit) {
        try 
        
            {     
                console.log(planEdit);  
                console.log("ArribaPlanEditado");
                console.log(planEdit._id);
                console.log("ArribaID");
            const planEditado = await axios.post(
                `${VUE_APP_VAULTPERK_URL}/plan/updatePlanById/${planEdit._id}`, planEdit, {
                    headers: {
                        perk_key: VUE_APP_PERK_KEY,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        }
                })
            console.log(planEditado.data);
            return {
                status: "200", 
                message: planEditado.data.plans,
            };
    
            } catch (error) {
                //console.log(error.response.data.detalle.message);
                // const message = error.response.data.detalle.message
                return {
                    status: "error", 
                    // message: message,
                };
            }
        }

    static async eliminarPlanes(plansIds) {
        try 
            {     
            console.log(plansIds);  
            console.log("ArribaIdsPlan");
            const eliminarIds = await axios.post(
                `${VUE_APP_VAULTPERK_URL}/plan/deletedPlansById`, plansIds, {
                    headers: {
                        perk_key: VUE_APP_PERK_KEY,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        }
                })
            console.log(eliminarIds.data);
            return {
                status: "200", 
                message: eliminarIds.data.plans,
            };
    
            } catch (error) {
                //console.log(error.response.data.message);
                const message = error.response.data.message
                return {
                    status: "error", 
                    message: message,
                };
            }
        }

        static async listadoSponsor() {
            try 
            
                {     
                console.log("traer Listado");
                const traerListado = await axios.get(
                    `${VUE_APP_VAULTPERK_URL}/plan/sponsorsAll`,{
                        headers: {
                            perk_key: VUE_APP_PERK_KEY,
                            'Access-Control-Allow-Origin': '*',
                          }
                    })
                console.log(traerListado.data.sponsors);
                return {
                    status: "200", 
                    message: traerListado.data.sponsors,
                };
        
                } catch (error) {
                    //console.log(error.response.data.detalle.message);
                    // const message = error.response.data.detalle.message
                    return {
                        status: "error", 
                        // message: message,
                    };
                }
            }
        

    
}
