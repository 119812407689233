<template>
  <section>

    <table id="ventas" class="table table-striped table-bordered display responsive nowrap select" style="width:100%">
      <thead>
        <tr>
          <th class="text-left">Sponsor</th>
          <th class="text-left">Rut</th>
          <th class="text-left">Nombre</th>
          <th class="text-left">Teléfono</th>
          <th class="text-left">Email</th>
          <th class="text-left">Fecha</th>
          <th class="text-left">Valor</th>
          <th class="text-left">Region</th>
          <th class="text-left">Dirección</th>
          <th class="text-left">Mascota</th>
          <th class="text-left">Tipo Mascota</th>
          <th class="text-left">Sexo Mascota</th>
          <th class="text-left">flowCustomerId</th>
          <th class="text-left">quoteRef</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in desserts" :key="index">
          <td>{{ item.sponsor }}</td>
          <td>{{ item.rut }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phoneNumber }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.value }}</td>
          <td>{{ item.region }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.pet }}</td>
          <td>{{ item.typePet }}</td>
          <td>{{ item.sexPet }}</td>
          <td>{{ item.flowCustomerId }}</td>
          <td>{{ item.quoteRef }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="text-left">Sponsor</th>
          <th class="text-left">Rut</th>
          <th class="text-left">Nombre</th>
          <th class="text-left">Teléfono</th>
          <th class="text-left">Email</th>
          <th class="text-left">Fecha</th>
          <th class="text-left">Valor</th>
          <th class="text-left">Region</th>
          <th class="text-left">Dirección</th>
          <th class="text-left">Mascota</th>
          <th class="text-left">Tipo Mascota</th>
          <th class="text-left">Sexo Mascota</th>
          <th class="text-left">flowCustomerId</th>
          <th class="text-left">quoteRef</th>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<script>
require('datatables.net-buttons/js/dataTables.buttons')
require('datatables.net-buttons/js/buttons.html5')

export default {
  name: "VentasTable",
  props: ["people", "types", "insurances", "prices", "pets", "sponsor", "LoadVentas"],
  data: () => ({

    //Datos nueva tabla
    desserts: [],
    buttonDisplay: true,

  }),
  async mounted() {
    if (this.LoadVentas == false) {
      await this.initialize();
    }
  },
  methods: {

    async initialize() {
      var aux;
      var contPet = 0
      this.people.forEach((person, index) => {
        if (person != null) {
          if (this.types[index] == 'pet') {
            aux = {
              rut: person.rut == null ? 'x' : person.rut,
              name: person.surname == null ? 'x' : person.name + ' ' + person.surname,
              phoneNumber: person.phoneNumber == null ? 'x' : person.phoneNumber,
              email: person.email == null ? 'x' : person.email,
              region: person.region == null ? 'x' : person.region,
              address: person.address == null ? 'x' : person.address,
              date: this.insurances[index].createdAt == null ? 'x' : this.insurances[index].createdAt.slice(0, 10),
              value: this.prices[index] == null ? 'x' : this.prices[index] + 'UF',
              type: this.types[index] == null ? 'x' : this.types[index],
              pet: this.pets[contPet].name == null ? 'x' : this.pets[contPet].name,
              typePet: this.pets[contPet].species == 'cat' && this.pets[contPet].species == 'cat' ? 'gato' : 'perro',
              sexPet: this.pets[contPet].sex == 'female' && this.pets[contPet].sex == 'female' ? 'femenino' : 'masculino',
              sponsor: this.sponsor[index] == null ? 'x' : this.sponsor[index],
              flowCustomerId: person.flowCustomerId == null ? 'x' : person.flowCustomerId,
              quoteRef: this.insurances[index].quoteRef == null ? 'x' : this.insurances[index].quoteRef,
            }
            this.desserts.push(aux)
          } else {
            aux = {
              rut: person.rut == null ? 'x' : person.rut,
              name: person.surname == null ? 'x' : person.name + ' ' + person.surname,
              phoneNumber: person.phoneNumber == null ? 'x' : person.phoneNumber,
              email: person.email == null ? 'x' : person.email,
              region: person.region == null ? 'x' : person.region,
              address: person.address == null ? 'x' : person.address,
              date: this.insurances[index].createdAt == null ? 'x' : this.insurances[index].createdAt.slice(0, 10),
              value: this.prices[index] == null ? 'x' : this.prices[index] + 'UF',
              type: this.types[index] == null ? 'x' : this.types[index],
              pet: '',
              typePet: '',
              sexPet: '',
              sponsor: this.sponsor[index] == null ? 'x' : this.sponsor[index],
              flowCustomerId: person.flowCustomerId == null ? 'x' : person.flowCustomerId,
              quoteRef: this.insurances[index].quoteRef == null ? 'x' : this.insurances[index].quoteRef,
            }
            this.desserts.push(aux)
          }
          contPet = contPet + 1
        } else {
          aux = {
            rut: '',
            name: '',
            phoneNumber: '',
            email: '',
            region: '',
            address: '',
            date: '',
            value: '',
            type: '',
            pet: '',
            typePet: '',
            sexPet: '',
            sponsor: '',
            flowCustomerId: '',
            quoteRef: ''
          }
          this.desserts.push(aux)
        }
      });
      this.table()
      this.LoadData = true
    },
    async table() {
      var $ = require('jquery');
      await this.$nextTick(() => {
        require('datatables.net-buttons/js/buttons.colVis.js')
        require('datatables.net-responsive-bs4')
        $('#ventas').DataTable({
          initComplete: function () {
            this.api()
              .columns()
              .every(function () {
                var column = this;
                var select = $('<select><option value="" selected="selected">Filtrar</option></select>')
                  .appendTo($(column.footer()).empty())
                  .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());


                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                  });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d) {
                    var val = $.fn.dataTable.util.escapeRegex(d);
                    if (column.search() === "^" + val + "$") {
                      select.append(
                        '<option value="' + d + '" selected="selected">' + d + "</option>"
                      );
                    } else {
                      select.append('<option value="' + d + '">' + d + "</option>");
                    }
                  });
              });
          },
          "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
          },
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'colvis',
              "text": "Filtros",
              "titleAttr": "Filtros",
            },
            {
              "extend": "copyHtml5",
              "text": "Copiar",
              "titleAttr": "Copiar",
              "className": "btn btn-secundary"
            },
            {
              "extend": "excelHtml5",
              "text": "<i class='fas fa-file-excel'></i> Excel",
              "titleAttr": "Exportar Excel",
              "className": "btn btn-success"
            },
            {
              "extend": "pdfHtml5",
              "text": "<i class='fas fa-file-pdf'></i> PDF",
              "titleAttr": "Copiar",
              "className": "btn btn-danger"
            },
            {
              "extend": "csvHtml5",
              "text": "<i class='fas fa-file-csv'></i> CSV",
              "titleAttr": "Expotar a CSV",
              "className": "btn btn-info"
            },
          ]
        })
      })
      this.buttonDisplay = false;
    },
    abrirModalView() {
      var $ = require('jquery');
      $('#example').on('shown.bs.modal', function () {
        $('#example').trigger('focus')
      })
    }
  }
}
</script>
