<template>
    <div class="dashboard" v-if="rol == 'sponsor'">
        <SideBar_Sponsor @next-dashboard="test" />
        <main v-if="type == 'gext'">
            <div v-if="e1 == 1">
                <Garantias_Sponsor />
            </div>
            <div v-else-if="e1 == 2">
                <Crear />
            </div>
        </main>
        <main v-else-if="type == 'seguro'">
            <div v-if="e1 == 1">
                <Ventas_Sponsor />
            </div>
            <div v-if="e1 == 2">
                <Carrito_Sponsor />
            </div>
            <!-- <div v-if="e1==3">
                <VentasPlan_Sponsor/>
            </div> -->
            <div v-else-if="e1 == 3">
                <Crear />
            </div>
        </main>
    </div>
    <div class="dashboard" v-else-if="rol == 'aseguradora'">
        <SideBar_Aseguradora :insurancesUser="insurancesUser" :garantiasUser="garantiasUser" :carsUser="carsUser"
            @next-dashboard="test" />
        <main>
            <div v-if="e1 == 1">
                <Bienvenida :LoadVentas_ventas="LoadVentas_ventas_aseguradora"
                    :LoadVentas_garantias="LoadVentas_garantias_aseguradora"
                    :LoadVentas_carrito="LoadVentas_carrito_aseguradora" :LoadVentas_cars="LoadVentas_cars_aseguradora" />
            </div>
            <div v-if="e1 == 2">
                <Ventas_Aseguradora :people="people_ventas_aseguradora" :types="types_ventas_aseguradora"
                    :insurances="insurances_ventas_aseguradora" :prices="prices_ventas_aseguradora"
                    :pets="pets_ventas_aseguradora" :sponsor="sponsor_ventas_aseguradora"
                    :LoadVentas="LoadVentas_ventas_aseguradora" :sponsors="sponsors_ventas_aseguradora"
                    :cantidades="cantidades_ventas_aseguradora" />
            </div>
            <div v-if="e1 == 3">
                <Garantias_Aseguradora :products="products_garantias_aseguradora" :prices="prices_garantias_aseguradora"
                    :types="types_garantias_aseguradora" :sponsors="sponsors_garantias_aseguradora"
                    :cantidades="cantidades_garantias_aseguradora" :LoadVentas="LoadVentas_garantias_aseguradora" />
            </div>
            <div v-if="e1 == 4">
                <Carrito_Aseguradora :plans="plans_carrito_aseguradora" :carritos="carritos_carrito_aseguradora"
                    :sponsors="sponsors_carrito_aseguradora" :cantidades="cantidades_carrito_aseguradora"
                    :LoadVentas="LoadVentas_carrito_aseguradora" />
            </div>
            <div v-if="e1 == 5">
                <Cars_Aseguradora :cars="cars_aseguradora" :cantidades="cantidades_cars_aseguradora"
                    :sponsors="sponsors_cars_aseguradora" :webpay="webpay_cars_aseguradora"
                    :LoadVentas="LoadVentas_cars_aseguradora" />
            </div>
        </main>
    </div>
    <div class="dashboard" v-else-if="rol == 'admin'">
        <SideBar_Admin @next-dashboard="test" />
        <main>
            <div v-if="e1 == 1">
                <Bienvenida :LoadVentas_ventas="LoadVentas_ventas_admin" :LoadVentas_garantias="LoadVentas_garantias_admin"
                    :LoadVentas_carrito="LoadVentas_carrito_admin" :LoadVentas_cars="LoadVentas_cars_admin" />
            </div>
            <div v-if="e1 == 2">
                <Ventas_Admin :people="people_ventas_admin" :types="types_ventas_admin"
                    :insurances="insurances_ventas_admin" :prices="prices_ventas_admin" :pets="pets_ventas_admin"
                    :sponsor="sponsor_ventas_admin" :LoadVentas="LoadVentas_ventas_admin" :sponsors="sponsors_ventas_admin"
                    :cantidades="cantidades_ventas_admin" />
            </div>
            <div v-if="e1 == 3">
                <Garantias_Admin :products="products_garantias_admin" :prices="prices_garantias_admin"
                    :types="types_garantias_admin" :sponsors="sponsors_garantias_admin"
                    :cantidades="cantidades_garantias_admin" :LoadVentas="LoadVentas_garantias_admin" />
            </div>
            <div v-if="e1 == 4">
                <Carrito_Admin :plans="plans_carrito_admin" :carritos="carritos_carrito_admin"
                    :sponsors="sponsors_carrito_admin" :cantidades="cantidades_carrito_admin"
                    :LoadVentas="LoadVentas_carrito_admin" />
            </div>
            <div v-if="e1 == 5">
                <Cars_Admin :cars="cars_admin" :cantidades="cantidades_cars_admin" :sponsors="sponsors_cars_admin"
                    :webpay="webpay_cars_admin" :LoadVentas="LoadVentas_cars_admin" />
            </div>
            <div v-else-if="e1 == 6">
                <Crear />
            </div>
            <div v-else-if="e1 == 8">
                <CrearPlanes />
            </div>
        </main>
    </div>
    <div class="dashboard" v-else-if="rol == 'callcenter'">
        <SideBar_CallCenter @next-dashboard="test" />
        <main>
            <div v-if="e1 == 1">
                <Cars_Consultora />
            </div>
            <div v-else-if="e1 == 2">
                <Crear />
            </div>
        </main>
    </div>
</template>

<script>
import SideBar_Sponsor from '@/components/SideBar_Sponsor.vue';
import Garantias_Sponsor from '@/components/Garantias.vue';
import Ventas_Sponsor from '@/components/Ventas.vue';
import Carrito_Sponsor from '@/components/Carrito.vue';

import Crear from '@/components/Crear.vue';
import CrearPlanes from '@/components/CrearPlanes.vue';
import Bienvenida from '@/components/Actualizar.vue';

import sessionHandler from "@/middleware/session.js";

//Admin
import infoVentasAdminService from '@/services/getInfoVentasAdmin.service';
import infoGarantiasAdminService from '@/services/getInfoGarantiasAdmin.service';
import infoCarritoAdminService from '@/services/getInfoCarritoAdmin.service';
import infoCarsAdminService from '@/services/getInfoCarsAdmin.service';

import SideBar_Admin from '@/components/SideBar_Admin.vue';
import Cars_Admin from '@/components/Cars_Admin.vue';
import Carrito_Admin from '@/components/Carrito_Admin.vue';
import Garantias_Admin from '@/components/Garantias_Admin.vue';
import Ventas_Admin from '@/components/Ventas_Admin.vue';

//Aseguradora
import infoVentasAseguradoraService from '@/services/getInfoVentasAseguradora.service';
import infoGarantiasAseguradoraService from '@/services/getInfoGarantiasAseguradora.service';
import infoCarritoAseguradoraService from '@/services/getInfoCarritoAseguradora.service';
import infoCarsAseguradoraService from '@/services/getInfoCarsAseguradora.service';


import SideBar_Aseguradora from '@/components/SideBar_Aseguradora.vue';
import Cars_Aseguradora from '@/components/Cars_Aseguradora.vue';
import Ventas_Aseguradora from '@/components/Ventas_Aseguradora.vue';
import Garantias_Aseguradora from '@/components/Garantias_Aseguradora.vue';
import Carrito_Aseguradora from '@/components/Carrito_Aseguradora.vue';

//CallCenter
import SideBar_CallCenter from '@/components/SideBar_CallCenter.vue';
import Cars_Consultora from '@/components/Cars_Consultora.vue';

export default {
    name: 'Dashboard',
    data: () => ({
        e1: 1,
        type: process.env.VUE_APP_TYPE,
        rol: localStorage.getItem("role"),
        insurancesUser: localStorage.getItem("sponsorsInsurance"),
        garantiasUser: localStorage.getItem("sponsorsGext"),
        carsUser: localStorage.getItem("sponsorsSoap"),

        //Admin
        //Ventas
        LoadVentas_ventas_admin: true,
        insurances_ventas_admin: [],
        types_ventas_admin: [],
        pets_ventas_admin: [],
        people_ventas_admin: [],
        prices_ventas_admin: [],
        sponsor_ventas_admin: [],
        sponsors_ventas_admin: [],
        cantidades_ventas_admin: [],
        //Garantias
        LoadVentas_garantias_admin: true,
        products_garantias_admin: [],
        prices_garantias_admin: [],
        types_garantias_admin: [],
        sponsors_garantias_admin: [],
        cantidades_garantias_admin: [],
        //Carrito
        LoadVentas_carrito_admin: true,
        plans_carrito_admin: [],
        carritos_carrito_admin: [],
        sponsors_carrito_admin: [],
        cantidades_carrito_admin: [],
        //Cars
        LoadVentas_cars_admin: true,
        cars_admin: [],
        sponsors_cars_admin: [],
        cantidades_cars_admin: [],
        webpay_cars_admin: [],

        //Aseguradora
        //Ventas
        LoadVentas_ventas_aseguradora: true,
        insurances_ventas_aseguradora: [],
        types_ventas_aseguradora: [],
        pets_ventas_aseguradora: [],
        people_ventas_aseguradora: [],
        prices_ventas_aseguradora: [],
        sponsor_ventas_aseguradora: [],
        sponsors_ventas_aseguradora: [],
        cantidades_ventas_aseguradora: [],
        //Garantias
        LoadVentas_garantias_aseguradora: true,
        products_garantias_aseguradora: [],
        prices_garantias_aseguradora: [],
        types_garantias_aseguradora: [],
        sponsors_garantias_aseguradora: [],
        cantidades_garantias_aseguradora: [],
        //Carrito
        LoadVentas_carrito_aseguradora: true,
        plans_carrito_aseguradora: [],
        carritos_carrito_aseguradora: [],
        sponsors_carrito_aseguradora: [],
        cantidades_carrito_aseguradora: [],
        //Cars
        LoadVentas_cars_aseguradora: true,
        cars_aseguradora: [],
        sponsors_cars_aseguradora: [],
        cantidades_cars_aseguradora: [],
        webpay_cars_aseguradora: [],

    }),
    components: {
        //Data general
        Crear,
        CrearPlanes,
        Bienvenida,

        //Data sponsor
        SideBar_Sponsor,
        Ventas_Sponsor,
        Garantias_Sponsor,
        Carrito_Sponsor,

        //Data admin
        Cars_Admin,
        Carrito_Admin,
        SideBar_Admin,
        Garantias_Admin,
        Ventas_Admin,

        //Data aseguradora
        Cars_Aseguradora,
        SideBar_Aseguradora,
        Ventas_Aseguradora,
        Carrito_Aseguradora,
        Garantias_Aseguradora,

        //Data callcenter
        SideBar_CallCenter,
        Cars_Consultora,
    },
    methods: {
        test(d) {
            this.e1 = d;
        },
    },
    mounted() {

        //Lógica para informació de administrador
        if (this.rol == 'admin') {
            infoVentasAdminService.getAllInsurances().then((response) => {
                this.insurances_ventas_admin = response[0]
                this.types_ventas_admin = response[1]
                this.pets_ventas_admin = response[2]
                this.people_ventas_admin = response[3]
                this.prices_ventas_admin = response[4]
                this.sponsor_ventas_admin = response[5]
                this.sponsors_ventas_admin = response[6]
                this.cantidades_ventas_admin = response[7]
                this.LoadVentas_ventas_admin = false
            })

            infoGarantiasAdminService.getProduct().then((response) => {
                this.products_garantias_admin = response[0]
                this.prices_garantias_admin = response[1]
                this.types_garantias_admin = response[2]
                this.sponsors_garantias_admin = response[3]
                this.cantidades_garantias_admin = response[4]
                this.LoadVentas_garantias_admin = false
            })

            infoCarritoAdminService.getCars().then((response) => {
                this.plans_carrito_admin = response[0]
                this.carritos_carrito_admin = response[1]
                this.sponsors_carrito_admin = response[2]
                this.cantidades_carrito_admin = response[3]
                this.LoadVentas_carrito_admin = false
            })

            infoCarsAdminService.getCars().then((response) => {
                this.cars_admin = response[0][0]
                this.webpay_cars_admin = response[0][1]
                this.cantidades_cars_admin = response[1]
                this.sponsors_cars_admin = response[2]
                this.LoadVentas_cars_admin = false
            })
            this.LoadVentas_cars_admin = false
            this.LoadVentas_carrito_admin = false
            this.LoadVentas_garantias_admin = false
            this.LoadVentas_ventas_admin = false
            

        } else if (this.rol == 'aseguradora') {

            if (this.insurancesUser) {
                infoVentasAseguradoraService.getAllInsurances().then((response) => {
                    this.insurances_ventas_aseguradora = response[0]
                    this.types_ventas_aseguradora = response[1]
                    this.pets_ventas_aseguradora = response[2]
                    this.people_ventas_aseguradora = response[3]
                    this.prices_ventas_aseguradora = response[4]
                    this.sponsor_ventas_aseguradora = response[5]
                    this.sponsors_ventas_aseguradora = response[6]
                    this.cantidades_ventas_aseguradora = response[7]
                    this.LoadVentas_ventas_aseguradora = false
                })

                infoCarritoAseguradoraService.getCars().then((response) => {
                    this.plans_carrito_aseguradora = response[0]
                    this.carritos_carrito_aseguradora = response[1]
                    this.sponsors_carrito_aseguradora = response[2]
                    this.cantidades_carrito_aseguradora = response[3]
                    this.LoadVentas_carrito_aseguradora = false
                })
            } else {
                this.LoadVentas_ventas_aseguradora = false
                this.LoadVentas_carrito_aseguradora = false
            }

            if (this.garantiasUser) {
                infoGarantiasAseguradoraService.getProduct().then((response) => {
                    this.products_garantias_aseguradora = response[0]
                    this.prices_garantias_aseguradora = response[1]
                    this.types_garantias_aseguradora = response[2]
                    this.sponsors_garantias_aseguradora = response[3]
                    this.cantidades_garantias_aseguradora = response[4]
                    this.LoadVentas_garantias_aseguradora = false
                })
            } else {
                this.LoadVentas_garantias_aseguradora = false
            }

            if (this.carsUser) {
                infoCarsAseguradoraService.getCars().then((response) => {
                    this.cars_aseguradora = response[0][0]
                    this.webpay_cars_aseguradora = response[0][1]
                    this.cantidades_cars_aseguradora = response[1]
                    this.sponsors_cars_aseguradora = response[2]
                    this.LoadVentas_cars_aseguradora = false
                })
            } else {
                this.LoadVentas_cars_aseguradora = false
            }
        }
        sessionHandler.sessionInit();
    }
}
</script>

<style lang="scss" scoped>
.dashboard {
    display: flex;
    width: 100%;
    height: 100%;

    main {
        width: 100%;
        background: red;

        >div {
            height: 100%;
            background: white;
        }
    }
}
</style>