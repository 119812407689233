<template>

  <section>

    <table id="cars" class="table table-striped table-bordered display responsive nowrap select" style="width:100%">
      <thead>
        <tr>
          <th class="text-left">Descargar</th>
          <th class="text-left">Nombre corredor</th>
          <th class="text-left">Rut corredor</th>
          <th class="text-left">Corredor DV</th>
          <th class="text-left">Nombre empresa</th>
          <th class="text-left">Rut empresa</th>
          <th class="text-left">Empresa DV</th>
          <th class="text-left">Sucursal</th>
          <th class="text-left">Nombre campaña</th>
          <th class="text-left">N° Póliza</th>
          <th class="text-left">N° Folio</th>
          <th class="text-left">Fecha Emisión</th>
          <th class="text-left">Inicio vigencia</th>
          <th class="text-left">Fin vigencia</th>
          <th class="text-left">Nombre pagador</th>
          <th class="text-left">Rut pagador</th>
          <th class="text-left">Pagador DV</th>
          <th class="text-left">Rut propietario</th>
          <th class="text-left">Propietario DV</th>
          <th class="text-left">Nombre propietario</th>
          <th class="text-left">Apellido paterno propietario</th>
          <th class="text-left">Apellido materno propietario</th>
          <th class="text-left">Email propietario</th>
          <th class="text-left">Teléfono propietario</th>
          <th class="text-left">Patente</th>
          <th class="text-left">Patente DV</th>
          <th class="text-left">Año vehículo</th>
          <th class="text-left">N° motor</th>
          <th class="text-left">Marca vehículo</th>
          <th class="text-left">Modelo vehículo</th>
          <th class="text-left">Tipo vehículo</th>
          <th class="text-left">Forma pago</th>
          <th class="text-left">Prima venta ($)</th>
          <th class="text-left">Remarque ($)</th>
          <th class="text-left">Puntos Cencosud</th>
          <th class="text-left">Estado póliza</th>
          <th class="text-left">Enviada correo</th>
          <th class="text-left">Informada AACH</th>
          <th class="text-left">Dígitos tarjeta</th>
          <th class="text-left">Fecha Pago</th>
          <th class="text-left">Fecha generación de pago</th>
          <th class="text-left">Fecha acutalización de pago</th>
          <th class="text-left">Código autorización Transbank</th>
          <th class="text-left">Link de descargar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in desserts" :key="index">
          <td><v-icon style="margin-left: 20%; max-width: 10px; max-height: 10px;" small class="mr-2"
              @click="DescargarArchivo(item.pdf, item.Patente)">
              mdi-download
            </v-icon></td>
          <td>{{ item.Nombre_corredor }}</td>
          <td>{{ item.Rut_corredor }}</td>
          <td>{{ item.Corredor_DV }}</td>
          <td>{{ item.Nombre_empresa }}</td>
          <td>{{ item.Rut_empresa }}</td>
          <td>{{ item.Empresa_DV }}</td>
          <td>{{ item.Sucursal }}</td>
          <td>{{ item.Nombre_campaña }}</td>
          <td>{{ item.NPóliza }}</td>
          <td>{{ item.NFolio }}</td>
          <td>{{ item.Fecha_Emisión }}</td>
          <td>{{ item.Inicio_vigencia }}</td>
          <td>{{ item.Fin_vigencia }}</td>
          <td>{{ item.Nombre_pagador }}</td>
          <td>{{ item.Rut_pagador }}</td>
          <td>{{ item.Pagador_DV }}</td>
          <td>{{ item.Rut_propietario }}</td>
          <td>{{ item.Propietario_DV }}</td>
          <td>{{ item.Nombre_propietario }}</td>
          <td>{{ item.Apellido_paterno_propietario }}</td>
          <td>{{ item.Apellido_materno_propietario }}</td>
          <td>{{ item.Email_propietario }}</td>
          <td>+569 {{ item.Teléfono_propietario }}</td>
          <td>{{ item.Patente }}</td>
          <td>{{ item.Patente_DV }}</td>
          <td>{{ item.Año_vehículo }}</td>
          <td>{{ item.NMotor }}</td>
          <td>{{ item.Marca_vehículo }}</td>
          <td>{{ item.Modelo_vehículo }}</td>
          <td>{{ item.Tipo_vehículo }}</td>
          <td>{{ item.Forma_pago }}</td>
          <td>{{ item.Prima_venta }}</td>
          <td>{{ item.Remarque }}</td>
          <td>{{ item.puntosCencosud }}</td>
          <td>{{ item.Estado_póliza }}</td>
          <td>{{ item.Enviada_correo }}</td>
          <td>{{ item.Informada_AACH }}</td>
          <td>{{ item.Card_digit }}</td>
          <td>{{ item.Fecha_Transaction }}</td>
          <td>{{ item.Fecha_pago }}</td>
          <td>{{ item.Fecha_pago_update }}</td>
          <td>{{ item.authorization_code }}</td>
          <td> <a style="color:blue" :href="item.link" target="_blank">{{ item.link }}</a></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="text-left">Descargar</th>
          <th class="text-left">Nombre corredor</th>
          <th class="text-left">Rut corredor</th>
          <th class="text-left">Corredor DV</th>
          <th class="text-left">Nombre empresa</th>
          <th class="text-left">Rut empresa</th>
          <th class="text-left">Empresa DV</th>
          <th class="text-left">Sucursal</th>
          <th class="text-left">Nombre campaña</th>
          <th class="text-left">N° Póliza</th>
          <th class="text-left">N° Folio</th>
          <th class="text-left">Fecha Emisión</th>
          <th class="text-left">Inicio vigencia</th>
          <th class="text-left">Fin vigencia</th>
          <th class="text-left">Nombre pagador</th>
          <th class="text-left">Rut pagador</th>
          <th class="text-left">Pagador DV</th>
          <th class="text-left">Rut propietario</th>
          <th class="text-left">Propietario DV</th>
          <th class="text-left">Nombre propietario</th>
          <th class="text-left">Apellido paterno propietario</th>
          <th class="text-left">Apellido materno propietario</th>
          <th class="text-left">Email propietario</th>
          <th class="text-left">Teléfono propietario</th>
          <th class="text-left">Patente</th>
          <th class="text-left">Patente DV</th>
          <th class="text-left">Año vehículo</th>
          <th class="text-left">N° motor</th>
          <th class="text-left">Marca vehículo</th>
          <th class="text-left">Modelo vehículo</th>
          <th class="text-left">Tipo vehículo</th>
          <th class="text-left">Forma pago</th>
          <th class="text-left">Prima venta ($)</th>
          <th class="text-left">Remarque ($)</th>
          <th class="text-left">Puntos Cencosud</th>
          <th class="text-left">Estado póliza</th>
          <th class="text-left">Enviada correo</th>
          <th class="text-left">Informada AACH</th>
          <th class="text-left">Dígitos tarjeta</th>
          <th class="text-left">Fecha Pago</th>
          <th class="text-left">Fecha generación de pago</th>
          <th class="text-left">Fecha acutalización de pago</th>
          <th class="text-left">Código autorización Transbank</th>
          <th class="text-left">Link de descargar</th>
        </tr>
      </tfoot>
    </table>

  </section>

</template>

<script>

export default {
  name: "CarsTable",
  props: ["cars", "webpay", "LoadVentas"],
  data: () => ({
    desserts: [],
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
  }),
  async mounted() {
    if (this.LoadVentas == false) {
      await this.initialize();
    }
  },
  methods: {
    initialize() {
      var aux;
      console.log("transactions: ", this.webpay)
      this.cars.forEach((car, index) => {
        aux = {
          Nombre_corredor: 'Scotia corredora de seguros Chile LTDA',
          Rut_corredor: '78745730',
          Corredor_DV: '4',
          Nombre_empresa: 'Banco Scotiabank',
          Rut_empresa: '97018000',
          Empresa_DV: '1',
          Sucursal: 'Venta online',
          Nombre_campaña: car?.campana? car.campana : '-',
          NPóliza: car?.numeroPoliza? car.numeroPoliza : '-',
          NFolio: car?.numeroFolio? car.numeroFolio : '-',
          Fecha_Emisión: car?.createdAt.slice(0, 10)? car.createdAt.slice(0, 10) : '-',
          Fecha_Transaction: this.webpay[index]?.transaction_date? this.webpay[index]?.transaction_date.slice(0, 10) + ' / ' + this.webpay[index].transaction_date?.slice(11, 19) : '-',
          Fecha_pago: this.webpay[index]?.createdAt? this.webpay[index].createdAt.slice(0, 10) + ' / ' + this.webpay[index].createdAt?.slice(11, 19) : '-',
          Fecha_pago_update: this.webpay[index]?.updatedAt? this.webpay[index].updatedAt.slice(0, 10) + ' / ' + this.webpay[index].updatedAt.slice(11, 19) : '-',
          Inicio_vigencia: '01-04-2023',
          Fin_vigencia: '31-03-2024',
          Nombre_pagador: '-',
          Rut_pagador: car?.rutPropietario? car.rutPropietario.slice(0, car.rutPropietario.length - 2) : '-',
          Pagador_DV: car?.rutPropietario? car.rutPropietario[car.rutPropietario.length - 1] : '-',
          Rut_propietario: car?.personRut? car.personRut.slice(0, car.personRut.length - 2) : '-',
          Propietario_DV: car?.personRut? car.personRut[car.personRut.length - 1] : '-',
          Nombre_propietario: car?.personNames? car.personNames : '-',
          Apellido_paterno_propietario: car?.personFirstName? car.personFirstName : '-',
          Apellido_materno_propietario: car?.personSecondName? car.personSecondName : '-',
          Email_propietario: car?.personEmail? car.personEmail : '-',
          Teléfono_propietario: car?.personPhoneNumber? car.personPhoneNumber : '-',
          Patente: car?.patenteNumber? car.patenteNumber : '-',
          Patente_DV: car?.patenteDV? car.patenteDV : '-',
          Año_vehículo: car?.yearVehicle? car.yearVehicle : '-',
          NMotor: car?.numberMotor? car?.numberMotor : '-',
          Marca_vehículo: car?.marcaVehicle? car.marcaVehicle : '-',
          Modelo_vehículo: car?.modelVehicle? car.modelVehicle : '-',
          Tipo_vehículo: car?.typeVehicle? car.typeVehicle : '-',
          Forma_pago: 'webPay', //si tiene transaction es webpay, si no puntos cencosud
          Prima_venta: car?.price? car.price : '-',
          Remarque: car?.remarque? car.remarque : '-',
          Estado_póliza: car?.status ? car.status : '-',
          Enviada_correo: 'Enviado',
          Informada_AACH: 'Carga exitosa',
          Card_digit: this.webpay[index]? this.webpay[index].card_detail?.card_number : '-',
          pdf: car?.pdf? car.pdf : '-',
          puntosCencosud: car?.pricePuntos? car.pricePuntos : '-',
          link: car?.numeroPoliza? process.env.VUE_APP_SITE_DOMAIN + 'docs?id=' + car.numeroPoliza : '-',
          authorization_code: this.webpay[index]? this.webpay[index].authorization_code: '-',
        }
        this.desserts.push(aux)

      });
      this.table()
    },
    DescargarArchivo(pdf, patente) {
      const url = window.URL.createObjectURL(new Blob([new Uint8Array(pdf.data).buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `vehiculo-${patente}.pdf`);
      document.body.appendChild(link);
      console.log(link)
      link.click();
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    table() {
      var $ = require('jquery');
      this.$nextTick(() => {
        require('datatables.net-buttons/js/buttons.colVis.js')
        require('datatables.net-responsive-bs4')
        $('#cars').DataTable({
          initComplete: function () {
            this.api()
              .columns()
              .every(function () {
                var column = this;
                var select = $('<select><option value="" selected="selected">Filtrar</option></select>')
                  .appendTo($(column.footer()).empty())
                  .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());


                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                  });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d) {
                    var val = $.fn.dataTable.util.escapeRegex(d);
                    if (column.search() === "^" + val + "$") {
                      select.append(
                        '<option value="' + d + '" selected="selected">' + d + "</option>"
                      );
                    } else {
                      select.append('<option value="' + d + '">' + d + "</option>");
                    }
                  });
              });
          },
          "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
          },
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'colvis',
              "text": "Filtros",
              "titleAttr": "Filtros",
            },
            {
              "extend": "copyHtml5",
              "text": "Copiar",
              "titleAttr": "Copiar",
              "className": "btn btn-secundary"
            },
            {
              "extend": "excelHtml5",
              "text": "<i class='fas fa-file-excel'></i> Excel",
              "titleAttr": "Exportar Excel",
              "className": "btn btn-success"
            },
            {
              "extend": "pdfHtml5",
              "text": "<i class='fas fa-file-pdf'></i> PDF",
              "titleAttr": "Copiar",
              "className": "btn btn-danger"
            },
            {
              "extend": "csvHtml5",
              "text": "<i class='fas fa-file-csv'></i> CSV",
              "titleAttr": "Expotar a CSV",
              "className": "btn btn-info"
            },
          ]
        })
      })
    },
  }
}
</script>

