import axios from "axios";
const { VUE_APP_VAULTPERK_URL, VUE_APP_SPONSOR } = process.env;

export default class guaranteeService {

  static async getAllPlanGarantia() {
    try { 

        //Llamada al controlador oncologico en V-P, para la busqueda de planes garantia
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getAllPlanGarantia`,
        );

        return vaultRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getPlans() {
    try { 
        
        const filters_plans = {
          sponsor: VUE_APP_SPONSOR,
          type: "oncologico"
        }

        //Llamada al controlador oncologico en SHIELD, para la busqueda de planes
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getPlans`, filters_plans
        );

        return vaultRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getProduct() {
    try { 
      
        //Llamada al controlador oncologico en SHIELD, para la busqueda de insurances
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getProduct`,
        );

        return vaultRes.data.data
        
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las productos",
      };
    }
  }

  static async getPeople(data) {
    try { 
      
        const filters_people = data

        //Llamada al controlador oncologico en SHIELD, para la busqueda de insurances
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getPerson`, filters_people
        );

        return vaultRes.data.data.data
        
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener al a persona",
      };
    }
  }
  
  static async getPlanId(filters_plans) {
    try {

        //Llamada al controlador oncologico en SHIELD, para la busqueda de planes
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getPlanId`, filters_plans
        );

        return vaultRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

}