<template>

  <section>

    <h1 style="color: white" class="text-center">Usuario {{index + 1}}</h1>

    <v-col>
      <v-text-field v-model="sponsor.name" :rules="nameRules" label="Ingrese el nombre" required filled flat solo
        background-color="#eef3f8" hide-details="auto">
        <v-icon slot="prepend"> mdi-account </v-icon>
      </v-text-field>
    </v-col>

    <v-col>
      <v-text-field v-model="sponsor.sponsors[0].sponsor" :rules="sponsorRules" label="Ingrese el sponsor" required filled flat solo
        background-color="#eef3f8" hide-details="auto">
        <v-icon slot="prepend"> mdi-reorder-horizontal </v-icon>
      </v-text-field>
    </v-col>

    <v-col>
      <v-text-field v-model="sponsor.email" :rules="emailRules" label="Ingrese el email" required filled flat solo
        background-color="#eef3f8" hide-details="auto">
        <v-icon slot="prepend"> mdi-at </v-icon>
      </v-text-field>
    </v-col>

    <v-col>
      <v-text-field v-model="sponsor.password" :rules="passwordRules" label="Ingrese la contraseña" required filled flat
        solo background-color="#eef3f8" hide-details="auto">
        <v-icon slot="prepend"> mdi-account-key </v-icon>
      </v-text-field>
    </v-col>

  </section>

</template>

<script>

export default {
  name: "VentasTable",
  data:()=>({
    cont: 0,
  }),
  props:{
    index:{type:String},
    title:{type:String},
    sponsor:{type:Array},
  },
  methods: {
    next(data){
      this.$emit('next', data)
    },
    prev(){
      this.$emit('prev')
    }
  }
};
</script>
<style lang="scss" scoped>

table{
  background: #f54077;
  border-radius: 30px;
  width: 80%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  tr{
    display: flex;
    justify-content: space-around;
    padding: 0.5rem 0rem;
    border-bottom: 1px solid gray;
    transition: background .1s;
    position: relative;
    z-index: 1;
    &:last-child{
        border: none;
    }
    &:first-child::after{
        background: transparent;
    }
    &:first-child:hover{
        cursor: default;
    }
    &::after{
        content: '';
        position: absolute;
        z-index: -1;
        left: -20px;
        right: -20px;
        height: 100%;
        background: #fa4b7f;
        transform: scaleX(0);
        opacity: 0;
        transition: transform .5s ease-in-out, opacity 1s;
        transform-origin: right;
        margin-top: -.5rem;
        border-radius: 20px;
    }
    &:hover::after{
        transform-origin: left;
        opacity: 1;
        transform: scaleX(1.2);
    }
    &:hover{
        cursor: pointer;
    }
    th, td{
        width: 100%;
        color:rgb(255, 255, 255)
    }
  }
}

section {
    margin-top: 1rem;
    h3{
      color: rgba(0, 0, 0, 0.692);
      text-align: center;
    }
    button{
      margin: auto;
      display: inherit;
      margin-top: 5px;
    }
  }
</style>