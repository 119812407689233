<template>

  <section>

    <table id="resumen" class="display nowrap" style="width:100%">
      <thead>
        <tr>
          <th class="text-left">Nombre</th>
          <th class="text-left">Cantidad</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in desserts" :key="index">
          <td>{{ item.name}}</td>
          <td>{{ item.count}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="text-left">Nombre</th>
          <th class="text-left">Email</th>
        </tr>
      </tfoot>
    </table>

  </section>

</template>

<script>

export default {
  name: "VentasTable",
  props: ["cantidades", "sponsors", "LoadVentas"], 
  data: () => ({
    desserts: [],
  }),
  async mounted() {
    if (this.LoadVentas == false) {
      await this.initialize();
    }
  },
  methods: {
    initialize() {
      var aux;
      this.sponsors.forEach((sponsor, index) => {
        aux = {
          name: sponsor,
          count: this.cantidades[index]
        }
        this.desserts.push(aux)
      });
      this.table()
    },
    table() {
      var $ = require('jquery');
      this.$nextTick(() => {
        $('#resumen').DataTable({

          initComplete: function () {
            this.api()
              .columns()
              .every(function () {
                var column = this;
                var select = $('<select><option value="" selected="selected">Filtrar</option></select>')
                  .appendTo($(column.footer()).empty())
                  .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());

                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                  });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d) {
                    select.append('<option value="' + d + '">' + d + '</option>');
                  });
              });
          },
          "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
          },
          searchPanes: true,
          dom: 'Bfrtip',
          buttons: [
            {
              "extend": "copyHtml5",
              "text": "Copiar",
              "titleAttr": "Copiar",
              "className": "btn btn-secundary"
            },
            {
              "extend": "excelHtml5",
              "text": "<i class='fas fa-file-excel'></i> Excel",
              "titleAttr": "Exportar Excel",
              "className": "btn btn-success"
            },
            {
              "extend": "pdfHtml5",
              "text": "<i class='fas fa-file-pdf'></i> PDF",
              "titleAttr": "Copiar",
              "className": "btn btn-danger"
            },
            {
              "extend": "csvHtml5",
              "text": "<i class='fas fa-file-csv'></i> CSV",
              "titleAttr": "Expotar a CSV",
              "className": "btn btn-info"
            },
          ]
        })
      })
    },
  },
};
</script>