<template>
  <section>

    <table id="garantia" class="table table-striped table-bordered display responsive nowrap select" style="width:100%">
      <thead>
        <tr>
          <th class="text-left">Sponsor</th>
          <th class="text-left">Rut</th>
          <th class="text-left">Tipo</th>
          <th class="text-left">Sku</th>
          <th class="text-left">Precio</th>
          <th class="text-left">Cobertura</th>
          <th class="text-left">Valor</th>
          <th class="text-left">Nombre</th>
          <th class="text-left">Email</th>
          <th class="text-left">Ciudad</th>
          <th class="text-left">Dirección</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in desserts" :key="index">
          <td>{{ item.sponsor }}</td>
          <td>{{ item.rut }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.sku }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.coverage }}</td>
          <td>{{ item.value }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.address }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="text-left">Sponsor</th>
          <th class="text-left">Rut</th>
          <th class="text-left">Tipo</th>
          <th class="text-left">Sku</th>
          <th class="text-left">Precio</th>
          <th class="text-left">Cobertura</th>
          <th class="text-left">Valor</th>
          <th class="text-left">Nombre</th>
          <th class="text-left">Email</th>
          <th class="text-left">Ciudad</th>
          <th class="text-left">Dirección</th>
        </tr>
      </tfoot>
    </table>

  </section>
</template>

<script>

export default {
  name: "VentasTable",
  props: ["products", "prices", "types", "LoadVentas"],
  data:()=>({
    //Datos nueva tabla
    desserts: [],
  }),
  async mounted() {
    if (this.LoadVentas == false) {
      await this.initialize();
    }
  },
  methods: {
    initialize() {
      var aux;
      this.products.forEach((product, index) => {
        aux = {
          rut: product.client_id == null ? 'x' : product.client_id,
          email: product.client_email == null ? 'x' : product.client_email,
          name: product.client_name == null ? 'x' : product.client_name,
          city: product.client_city == null ? 'x' : product.client_city,
          address: product.client_adress == null ? 'x' : product.client_adress,
          sponsor: product.sponsor == null ? 'x' : product.sponsor,
          category: product.product_category == null ? 'x' : product.product_category,
          type: this.types[index] == null ? 'x' : this.types[index],
          sku: product.product_sku == null ? 'x' : product.product_sku,
          price: product.product_price + ' ' + product.product_unit_price,
          coverage: product.warranty_period + ' ' + product.warranty_period_unit,
          value: this.prices[index] + ' ' + product.product_unit_price,
        }
        this.desserts.push(aux)
      });
      this.table()
    },
    table() {
      var $ = require('jquery');
      this.$nextTick(() => {
        require('datatables.net-buttons/js/buttons.colVis.js')
        require('datatables.net-responsive-bs4')
        $('#garantia').DataTable({
          initComplete: function () {
            this.api()
              .columns()
              .every(function () {
                var column = this;
                var select = $('<select><option value="" selected="selected">Filtrar</option></select>')
                  .appendTo($(column.footer()).empty())
                  .on('change', function () {
                    var val = $.fn.dataTable.util.escapeRegex($(this).val());


                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                  });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d) {
                    var val = $.fn.dataTable.util.escapeRegex(d);
                    if (column.search() === "^" + val + "$") {
                      select.append(
                        '<option value="' + d + '" selected="selected">' + d + "</option>"
                      );
                    } else {
                      select.append('<option value="' + d + '">' + d + "</option>");
                    }
                  });
              });
          },
          "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
          },
          dom: 'Bfrtip',
          buttons: [
            { 
              extend: 'colvis',
              "text": "Filtros",
              "titleAttr": "Filtros",
            },
            {
              "extend": "copyHtml5",
              "text": "Copiar",
              "titleAttr": "Copiar",
              "className": "btn btn-secundary"
            },
            {
              "extend": "excelHtml5",
              "text": "<i class='fas fa-file-excel'></i> Excel",
              "titleAttr": "Exportar Excel",
              "className": "btn btn-success"
            },
            {
              "extend": "pdfHtml5",
              "text": "<i class='fas fa-file-pdf'></i> PDF",
              "titleAttr": "Copiar",
              "className": "btn btn-danger"
            },
            {
              "extend": "csvHtml5",
              "text": "<i class='fas fa-file-csv'></i> CSV",
              "titleAttr": "Expotar a CSV",
              "className": "btn btn-info"
            },
          ]
        })
      })
    },
    abrirModalView() {
      var $ = require('jquery');
      $('#example').on('shown.bs.modal', function () {
        $('#example').trigger('focus')
      })
    }
  }
}
</script>