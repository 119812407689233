<template>
  <div class="text-center">
    <iframe
      src='https://docs.google.com/forms/d/e/1FAIpQLScWvSYwgmrUx-UXunpqFXi4XCGc-kai1NjEhxIz-MJqFSX6Hg/viewform?embedded=true'
      width='640' height='864' frameborder='0' marginheight='0' marginwidth='0'>Cargando…</iframe>
  </div>
</template>

<script>


export default {
  name: "Home",
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>


