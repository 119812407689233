/* 
Una posible modificacion es utilizar el Bus sólo para comunicar eventos
y el sessionStorage para almacenar la información.
*/

import {Bus} from '../main.js';
const axios = require('axios');
const { VUE_APP_SHIELD_URL } = process.env;

export default class sessionHandler{
    static async sessionInit(){

        if (Bus.var || sessionStorage.Bus){
            // Setea la data en el Bus o en el sessionStorage
            Bus.var?sessionStorage.Bus = JSON.stringify(Bus.var):Bus.var=JSON.parse(sessionStorage.Bus);

            // Checkear si la sesión sigue abierta
            let res = await axios.get(VUE_APP_SHIELD_URL+'/user/verifyToken', {
                headers:{
                  'x-access-token': JSON.parse(sessionStorage.Bus).accessToken
                }
            })
            res = res.data;
            // Checkea si el rol en el Bus y en el verifyToken son distintos
            if (res.status!=='ok' || res.role!==Bus.var.role){
                window.open('/','_self');
            }

        } else {
            window.open('/','_self');
        }
    }


}

