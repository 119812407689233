<template>
    <aside style="min-width: 200px; max-width: 200px;" v-if="type == 'gext'">
        <img :src="Logo" alt="Logo">

        <nav>
            <div>
                <a @click="test(1)">Servicios vendidos</a>
                <a @click="test(2)">Crear perfil</a>
                <!-- <a @click="test(3)">Actualizar perfil</a> -->
            </div>
            <a style="background-color: #FE195E; color: white;" class="text-center" @click="test(3)">LogOut</a>

        </nav>
    </aside>

    <aside style="min-width: 200px; max-width: 200px;" v-else>
        <img :src="Logo" alt="Logo">

        <nav>
            <div>
                <a @click="test(1)">Seguros vendidos</a>
                <a @click="test(2)">Leads</a>
                <!-- <a @click="test(3)">Ventas por plan</a> -->
                <a @click="test(3)">Crear perfil</a>
                <!-- <a @click="test(4)">Actualizar perfil</a> -->
            </div>
            <a style="background-color: #FE195E; color: white;" class="text-center" @click="test(4)">LogOut</a>

        </nav>
    </aside>
</template>

<script>
import Logo from '@/assets/images/embed.png'

export default {
    name: 'SideBar',
    data: ()=>({
        Logo,
        type: process.env.VUE_APP_TYPE,
    }),
    methods: {
        test(d){
            this.$emit('next-dashboard', d);
        }
    }
}
</script>

<style lang="scss" scoped>
aside{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top: 1rem;
    img{
        max-width: 80%;
    }
    nav{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;
        >div{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
        }
        a{
            position: relative;
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: lightblue;
                height: 1px;
                border-radius: 1px;
                transform: scaleX(0);
                transition: transform .3s ease;
            }
            &:hover::after{
                transform: scaleX(1);
            }
        }
    }
}
</style>