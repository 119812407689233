import axios from "axios";
const { VUE_APP_VAULTPERK_URL, VUE_APP_SPONSOR } = process.env;

export default class SalesService {

  static async getAllPlanSeguro(filters_plans) {
    try { 

        //Llamada al controlador oncologico en V-P, para la busqueda de planes seguro
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getAllPlanSeguro`, filters_plans
        );

        return vaultRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getPlans() {
    try { 
        
        const filters_plans = {
          sponsor: VUE_APP_SPONSOR,
          type: "oncologico"
        }

        //Llamada al controlador oncologico en V-P, para la busqueda de planes
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getPlans`, filters_plans
        );

        return vaultRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getInsurances(data) {
    try { 
      
        const filters_insurances = data

        //Llamada al controlador oncologico en V-P, para la busqueda de insurances
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getInsurances`, filters_insurances
        );

        return vaultRes.data.data
        
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las insurances",
      };
    }
  }

  static async getPeople(data) {
    try { 
      
        const filters_people = data

        //Llamada al controlador oncologico en V-P, para la busqueda de insurances
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getPerson`, filters_people
        );

        return vaultRes.data.data.data
        
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener al a persona",
      };
    }
  }

  static async getPet(data) {
    try { 
      
        const filters_pet = data

        //Llamada al controlador oncologico en V-P, para la busqueda de insurances
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getPet`, filters_pet
        );
        return vaultRes.data.data.data
        
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener a la mascota",
      };
    }
  }

  static async getAllInsurances() {
    try { 

        //Llamada al controlador oncologico en V-P, para la busqueda de insurances
        const vaultRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getAllInsurances`,
        );
        return vaultRes.data.data.data
        
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener a las insurances",
      };
    }
  }

  static async getPlanId(filters_planId) {
    try { 

        //Llamada al controlador oncologico en V-P, para la busqueda de planes garantia
        const solairetRes = await axios.post(
            `${VUE_APP_VAULTPERK_URL}/sales/getPlanId`, filters_planId
        );

        return solairetRes.data.data

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los carritos",
      };
    }
  }

}