import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Docs from "../views/Docs.vue";
import bucket from "../views/Bucket.vue";
import Dashboard from "../views/Dashboard.vue";
import Formulario from "../views/Formulario.vue";
import Ventas from "../components/Ventas.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'EmbedX', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'EmbedX - Insured'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'EmbedX - Insured'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/docs",
    name: "docs",
    component: Docs,
    meta: {
      title: 'EmbedX', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'EmbedX - Insured'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'EmbedX - Insured'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/bucket",
    name: "bucket - Soap 2023",
    component: bucket,
    meta: {
      title: 'EmbedX', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'EmbedX - Insured'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'EmbedX - Insured'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    childrem: [
      {path:'/ventas', component: Ventas}
    ]
  },
  {
    path: "/formulario",
    name: "Formulario",
    component: Formulario,
  },
  // {
  //   path: "/cotizador",
  //   name: "Contrata",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Quote.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//... Cambiar todos los fronts por router mas avanzado
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router;
