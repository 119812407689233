<template>
    <aside style="min-width: 200px; max-width: 200px;">
        <img :src="Logo" alt="Logo">

        <nav>
            <div>
                <a @click="test(1)">Consultar</a>
                <!-- <a @click="test(5)">Actualizar perfil</a> -->
            </div>
            <a style="background-color: #FE195E; color: white;" class="text-center" @click="test(2)">LogOut</a>

        </nav>
    </aside>
</template>

<script>
import swal from 'sweetalert';
import Logo from '@/assets/images/embed.png'

export default {
    name: 'SideBar',
    props: [],
    data: () => ({
        Logo,
        type: process.env.VUE_APP_TYPE,
    }),
    methods: {
        test(d) {
            if (d == 6) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Tendrás que iniciar nuevamente sesión.",
                    icon: "info",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        localStorage.removeItem("role");
                        localStorage.removeItem("email");
                        localStorage.removeItem("id");
                        localStorage.removeItem("accessToken");
                        window.location.href = "https://embedx.io/";
                    } else {
                        swal({
                            title: "No se ha cerrado sesión.",
                            icon: "warning"
                        });
                    }
                });
            } else {
                this.$emit('next-dashboard', d);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
aside {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top: 1rem;

    img {
        max-width: 80%;
    }

    nav {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;

        >div {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
        }

        a {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: lightblue;
                height: 1px;
                border-radius: 1px;
                transform: scaleX(0);
                transition: transform .3s ease;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }
    }
}</style>