import amelieService from '@/services/cars.service'

export default class ShieldService {

    static async getCars() {

        var params = []
        var cars = []
        var sponsors = []
        var cantidades = []
        var loadData = true

        //Se realiza el llamado al servicio
        await amelieService.getCars().then((response) => {
            do {
                console.log(response)
                response[0].forEach( car => {
                    if (sponsors.includes(car.sponsor) == true) {
                        var fuction = (sponsor) => sponsor == car.sponsor
                        var indice = sponsors.findIndex(fuction)
                        cantidades[indice] = cantidades[indice] + 1
                    } else {
                        sponsors.push(car.sponsor)
                        cantidades.push(1)
                    }
                    cars.push(car)
                });
                loadData = false

            } while (loadData == true);

            params = [[cars,response[1]], cantidades, sponsors]
        })
        
        if(loadData == false){
            return params
        }

    }
}