import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;

export default class ShieldService {
  static async createUser(userData, sponsors, captchaToken) {
    var params = { userData, sponsors };
    
    var user = await axios.post(`${VUE_APP_SHIELD_URL}/amelie/create`, params, {
      headers: {
        // Se agrega el Header de authorización
        "x-captcha-authorization": captchaToken,
      },
    });

    return user;
  }

  static async deleteUser(id, email) {
    var params = { id, email };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/amelie/delete`,
      params
    );
    return shieldRes;
  }

  static async updateUser(data) {
    var params = { data };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/amelie/update`,
      params
    );
    return shieldRes;
  }

  // Crear get All
  static async getAll() {
    const res = await axios.get(VUE_APP_SHIELD_URL + "/user/get", {
      headers: {
        "x-access-token": JSON.parse(sessionStorage.Bus).accessToken,
      },
    });
    return res.data;
  }
}
