import guaranteeService from '@/services/garantias.service'
import infoService from '@/services/getInfoGarantiasAseguradora.service'

export default class ShieldService {

    static async getProduct() {

        var products = []
        var prices = []
        var types = []
        var sponsors = []
        var cantidades = []
        var params = []
        var sponsors_account = localStorage.getItem("sponsorsGext")

        sponsors_account = sponsors_account.split('+')

        //Se realiza el llamado al servicio
        await guaranteeService.getProduct().then((response) => {
            //Una vez obtenida la respuesta, se recorren los datos
            response.data.forEach(product => {
                //Se busca el plan asociado mediante la funcion
                infoService.getPlanId(product, products, prices, types, sponsors, cantidades, sponsors_account)
            });
            params = [products, prices, types, sponsors, cantidades]
        })

        return params

    }

    static async getPlanId(product, products, prices, types, sponsors, cantidades, sponsors_account) {
        //Se inicializan los filtros
        var filters_plans = {
            plan: product.plan_id_perk
        }
        //Se realiza el llamado al servicio
        await guaranteeService.getPlanId(filters_plans).then((plan) => {
            sponsors_account.forEach((element) => {
                if (element == plan.data[0].sponsor) {
                    //Se almacena cada producto
                    products.push(product)
                    //Se recorren los precios del plan
                    plan.data[0].values.forEach(price => {
                        if (price.validity.quantity == product.warranty_period) {

                            if (sponsors.includes(plan.data[0].sponsor) == true) {
                                var fuction = (sponsor) => sponsor == plan.data[0].sponsor
                                var indice = sponsors.findIndex(fuction)
                                cantidades[indice] = cantidades[indice] + 1
                            } else {
                                sponsors.push(plan.data[0].sponsor)
                                cantidades.push(1)
                            }
                            //Se almacena el precio seleccionado
                            prices.push(price.price)
                            //Se almacena el typo de plan
                            types.push(plan.data[0].type)
                        }
                    });
                }
            })
        })
    }
}