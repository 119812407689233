import salesService from '@/services/ventas.service'
import infoService from '@/services/getInfoVentasAseguradora.service'

export default class ShieldService {

    static async getAllInsurances() {

        var insurances = []
        var types = []
        var pets = []
        var people = []
        var prices = []
        var sponsor = []
        var sponsors = []
        var cantidades = []
        var params = []
        var sponsors_user = localStorage.getItem("sponsorsInsurance")

        sponsors_user = sponsors_user.split('+')

        //Se realiza el llamado al servicio para traer plans
        await salesService.getAllInsurances().then((response) => {
            //Una vez obtenida la respuesta, se alamcenan las insurances con flag OK
            response.forEach(insurance => {
                infoService.getPlans(insurance, insurances, types, pets, people, prices, sponsor, sponsors, cantidades, sponsors_user)
            });
            params = [insurances, types, pets, people, prices, sponsor, sponsors, cantidades]
        })

        return params

    }

    static async getPlans(insurance, insurances, types, pets, people, prices, sponsor, sponsors, cantidades, sponsors_user) {
        var filters_planId = {
            plan: insurance.plan,
        }

        await salesService.getPlanId(filters_planId).then((response => {
            if (response.data.length != 0) {
                sponsors_user.forEach((element) => {
                    if (element == response.data[0].sponsor) {
                        insurances.push(insurance)
                        if (sponsors.includes(response.data[0].sponsor) == true) {
                            var fuction = (sponsor) => sponsor == response.data[0].sponsor
                            var indice = sponsors.findIndex(fuction)
                            cantidades[indice] = cantidades[indice] + 1
                        } else {
                            sponsors.push(response.data[0].sponsor)
                            cantidades.push(1)
                        }
                        //Se almacena el tipo de plan
                        types.push(response.data[0].type)
                        //Se almacena el sponsor de plan
                        sponsor.push(response.data[0].sponsor)
                        //Se almacena el precio del plan relacionado en un arreglo general
                        prices.push(response.data[0].price)
                        if (response.data[0].type == "pet") {
                            //Se establecen filtros para la busqueda de la mascota
                            var filters_pet = {
                                id: insurance.pet
                            }
                            //Se llama a la función que busca a la persona realcionada a la insurance
                            infoService.getPet(filters_pet, pets)
                        }
                        //Se establecen filtros para la busqueda de la persona
                        var filters_person = {
                            id: insurance.person
                        }
                        //Se llama a la función que busca a la persona realcionada a la insurance
                        infoService.getPeople(filters_person, people)
                    }
                })
            } else {
                insurances.push(insurance)
                people.push(null)
            }
        }))
    }

    //Funcion para traer insurances
    static async getPeople(filters_person, people) {
        //Se realiza el llamado al servicio para traer persons
        await salesService.getPeople(filters_person).then((person) => {
            //Se almacena cada persona obtenida
            people.push(person)
        })
    }

    static async getPet(filters_pet, pets) {
        //Se realiza el llamado al servicio para traer pets
        await salesService.getPet(filters_pet).then((pet) => {
            //Se almacena cada persona obtenida
            pets.push(pet)
        })
    }

}