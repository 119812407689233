import amelieService from '@/services/cars.service'

export default class ShieldService {

    static async getCars() {

        var params = []
        var cars = []
        var webpays = []
        var sponsors = []
        var cantidades = []
        var loadData = true

        var sponsors_account = localStorage.getItem("sponsorsSoap")

        sponsors_account = sponsors_account.split('+')

        //Se realiza el llamado al servicio
        await amelieService.getCars().then((response) => {

            do {
                sponsors_account.forEach(SoapSponsor => {
                    response[0].forEach( (car, index) => {
                        if (SoapSponsor == car.sponsor) {
                            if (sponsors.includes(car.sponsor) == true) {
                                var fuction = (sponsor) => sponsor == car.sponsor
                                var indice = sponsors.findIndex(fuction)
                                cantidades[indice] = cantidades[indice] + 1
                            } else {
                                sponsors.push(car.sponsor)
                                cantidades.push(1)
                            }
                            cars.push(car)
                            webpays.push(response[1][index])
                        }
                    });
                });
                loadData = false

            } while (loadData == true);

            params = [[cars, webpays], cantidades, sponsors]
        })

        if (loadData == false) {
            return params
        }

    }
}