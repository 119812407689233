import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueSimpleAlert from "vue-simple-alert";
import VuePdfApp from "vue-pdf-app";



export const Bus = new Vue({
  data: {
    msg:'HolaMundo desde el bus',
    var:null
  }
});

Vue.config.productionTip = false
Vue.config.silent = true // Deshabilitar para hacer QA.
Vue.component("vue-pdf-app", VuePdfApp);


Vue.use(VueSimpleAlert);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
