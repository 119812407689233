 <template>
    <section class="section_1">
    <v-form ref="form" v-model="valid" style="max-width: 900px">
      <br />
      <div align="left">
        <h3>Usuario</h3>
        <v-text-field
          v-model="userData.email"
          :rules="userRules"
          placeholder="Ingrese Su Usuario EmbedX"
          required
          filled
          flat
          solo
          background-color="#eef3f8"
        ></v-text-field>
      </div>

      <br />
      <div align="left">
        <h3>Contraseña</h3>
        <v-text-field
          v-model="userData.password"
          type="password"
          :rules="passRules"
          placeholder="Ingrese Su Contraseña"
          required
          filled
          flat
          solo
          background-color="#eef3f8"
        >
        </v-text-field>
      </div>

      <div class="button-container">
        <v-btn @click="validate()"> Iniciar Sesion </v-btn>
      </div>
    </v-form>
   
  </section>
</template>

<script>
import LoginService from "@/services/login.service.js";

export default {
  name: "Login",
  data: () => ({
    userData: {
      email: null,
      password: null,
    },

    valid: false,
    userRules: [(v) => !!v || "El Usuario es requerido"],
    passRules: [(v) => !!v || "La Contraseña es requerida"],
  }),
  mounted() {
  },
  methods: {
    SaveDates(dates) {
      this.$refs.menu.save(dates);
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if (this.userData.password && this.userData.email) {
          // this.$alert("Paso Siguiente", "Informacion", "info");
          //Creacion de servicio
          try {
            const res = await LoginService.LoginUser(
              this.userData.email,
              this.userData.password
            );

            if (res.status === "ok") {
              res.data.accessToken = res.accessToken;
              if(res.data.role == "admin"){
                localStorage.setItem("role", res.data.role);          
                localStorage.setItem("email", res.data.email);          
                localStorage.setItem("id", res.data.id);          
                localStorage.setItem("accessToken", res.data.accessToken);          
                this.$emit("login-success", res.data);
              } else if(res.data.role == "callcenter"){
                localStorage.setItem("role", res.data.role);          
                localStorage.setItem("email", res.data.email);          
                localStorage.setItem("id", res.data.id);          
                localStorage.setItem("accessToken", res.data.accessToken);          
                this.$emit("login-success", res.data);
              } else if (res.data.role == "aseguradora") {
                localStorage.setItem("role", res.data.role);
                localStorage.setItem("email", res.data.email);
                localStorage.setItem("id", res.data.id);
                var sponsorsGext = ''
                res.data.sponsorsGext.forEach(element => {
                  sponsorsGext = sponsorsGext + element.sponsor + '+'
                });
                localStorage.setItem("sponsorsGext", sponsorsGext);
                var sponsorsInsurance = ''
                res.data.sponsorsInsurance.forEach(element => {
                  sponsorsInsurance = sponsorsInsurance + element.sponsor + '+'
                });
                localStorage.setItem("sponsorsInsurance", sponsorsInsurance);
                var sponsorsSoap = ''
                res.data.sponsorsSoap.forEach(element => {
                  sponsorsSoap = sponsorsSoap + element.sponsor + '+'
                });
                localStorage.setItem("sponsorsSoap", sponsorsSoap);
                this.$emit("login-success", res.data);
              } else if(res.data.role == "sponsor"){
                localStorage.setItem("role", res.data.role);          
                localStorage.setItem("email", res.data.email);          
                localStorage.setItem("id", res.data.id);          
                localStorage.setItem("accessToken", res.data.accessToken);          
                this.$emit("login-success", res.data);
              }else {
                this.$alert(
                  "No posee permisos",
                  "Error",
                  "error"
                );
              }

              // this.$alert("Login Exitoso", "Informacion", "success");
            } else {
              this.$alert(
                "Credenciales Invalidas, Intente nuevamente",
                "Error",
                "error"
              );
            }
          } catch (error) {
            console.log(error);
            this.$alert(
              "Ocurrió un error. Verifica que tus datos sean correctos",
              "Error",
              "error"
            );
          }
        } else {
          //Validacion Fuerte
          this.$alert("Error", "Error", "error");
        }

        //console.log(this.userData)
        //this.$emit("next-step");
      }
    },
  },
};
</script>




<style lang="scss">
  .section_1{
   margin-top: 150px; 
  }
.v-alert--text {
  margin-bottom: 0 !important;
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
}

.section-uno {
  padding: 5vh 0 30vh;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  div {
    width: 40%;
    h1 {
      font-size: 64px;
    }
    p {
      font-size: 24px;
      span {
        font-weight: 200;
      }
    }
    &:last-child {
      width: 40%;
      display: flex;
      align-items: flex-start;
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .section-uno {
    div {
      width: 45%;
      h1 {
        font-size: 55px;
      }
      p {
        font-size: 18px;
      }
      &:last-child {
        width: 35%;
        padding-top: 3rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .section-uno {
    div {
      width: 90%;
      &:last-child {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .section-uno {
    align-items: center;
    div {
      text-align: center;
      h1 {
        font-size: 2.5rem;
      }
    }
  }
}
</style>