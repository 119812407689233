<template>
    <div>
        <section>

            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>

            <template>
                <!-- <div v-if="LoadVentas_ventas == true || LoadVentas_garantias == true || LoadVentas_carrito==true" -->
                <div style="margin:auto;" role="status">
                    <div class="text-center">
                        <button
                            v-if="LoadVentas_ventas == true || LoadVentas_garantias == true || LoadVentas_carrito == true || LoadVentas_cars == true || loadData == true"
                            class="btn btn-primary" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>

                        <div
                            v-if="LoadVentas_ventas == false && LoadVentas_garantias == false && LoadVentas_carrito == false && LoadVentas_cars == false && loadData == false">
                            ¡Bienvenido, ya tiene la información cargada! Puedes acceder a los distintos paneles!
                        </div>
                    </div>
                </div>
            </template>

        </section>
    </div>
</template>

<script>

export default {
    name: 'Actualizar',
    props: ["LoadVentas_ventas", "LoadVentas_garantias", "LoadVentas_carrito", "LoadVentas_cars"],
    data: () => ({
        loadData: true,
    }),
    components: {
    },
    mounted() {
        setTimeout(() => {
            this.mostrarDatos()
        }, 4000);
    },
    methods: {
        mostrarDatos(){
            this.loadData = false
        }
    }
}
</script>

<style lang="scss" scoped>

</style>