import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;

export default class LoginService {
  static async LoginUser(user, pass) {
    try 
        {       
        
        //Llamada al controlador oncologico en SHIELD, para la busqueda de planes
        const shieldRes = await axios.post(
            `${VUE_APP_SHIELD_URL}/user/login`,
            {
            email : user,
            password: pass,
            }
        )
        // .then((res)=>{
        //     console.log('Todo en orden Hiuston')
        //     console.log(res)
        // }).catch(err=>{
        //     console.log('Hiuston, tenemos un problema')
        //     console.log(err)
        // });
        
        return shieldRes.data.status === "ok" ? shieldRes.data : [];
        } catch (error) {
            return {
                status: "error",
                message: "Ocurrio un error al obtener los planes" + error,
            };
        }
    }
}
