<template>
  <div class="home">
    <HeaderLogin/>
    <v-alert v-if="success === true" dense text type="success">
      ¡Has contratado tu seguro con éxito! Revisa tu <strong>correo electrónico</strong> para obtener toda la información.
    </v-alert>
    <v-alert v-if="success === false" dense text type="error">
      Ha ocurrido un error al contratar tu seguro. Intenta nuevamente.
    </v-alert> 
    <Welcome />
    <FooterLogin/>
  </div>
</template>

<script>
import Welcome from "@/components/Welcome.vue"
import HeaderLogin from '@/components/HeaderLogin.vue';
import FooterLogin from '@/components/FooterLogin.vue';


export default {
  name: "Home",
  props: ["userData"],
  components: {
    Welcome,
    HeaderLogin,
    FooterLogin

  },
  data() {
    return {
      success: null,
    };
  },
  mounted() {
    const query = this.$route.query;
    if (query) {
      if (query.success === "true") {
        this.success = true;
      } else if (query.success === "false") {
        this.success = false;
      }
    }
  },
};
</script>


