<template>
  <v-app>
      <!-- <NavBar /> -->
    <v-main>
      <router-view @login="test"/>
    </v-main>
    <!-- <Footer /> -->
  </v-app>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";
// import Footer from "@/components/Footer.vue";
import {Bus} from './main.js'

export default {
  components: {
    // Footer,
    // NavBar
  },
  data: ()=>({
    isLoged:false
  }),
  created (){
    Bus.$on('login', () => {
      if (!this.isLoged){
        this.isLoged = true;
      }
    })
  }

};
</script>