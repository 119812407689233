<template>
  <section>

    <v-data-table v-if="sponsor == plans[0].sponsor" :search="search" :headers="headers" :items="desserts"
      sort-by="email" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Garantías extendidas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1200px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Información</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>

                    <table>
                      <tr style="color: #fff; background:#FE195E">
                        <th>Sponsor</th>
                        <th>Categoría</th>
                        <th>Tipo</th>
                        <th>Sku</th>
                        <th>Precio</th>
                        <th>Cobertura</th>
                        <th>Valor</th>
                      </tr>
                      <tr style="color: #fff; background:#f54077">
                        <td>{{ editedItem.sponsor }}</td>
                        <td>{{ editedItem.category }}</td>
                        <td>{{ editedItem.type }}</td>
                        <td>{{ editedItem.sku }}</td>
                        <td>{{ editedItem.price }}</td>
                        <td>{{ editedItem.coverage }}</td>
                        <td>{{ editedItem.value }}</td>
                      </tr>
                    </table>

                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#fff" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="#fff" text @click="save">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon style="background-color: #fff; border: #black;" small @click="editItem(item)">
          mdi-plus
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Cargar datos
        </v-btn>
      </template>
    </v-data-table>

  </section>
</template>
<script>
export default {
  name: "VentasTable",
  props: {
    indexSponsor: { type: String },
    title: { type: String },
    sponsor: { type: String },
    plans: {type: Array},
    types: { type: Array },
    prices: { type: Array },
    products: { type: Array },
  },
  data: () => ({

    //Datos nueva tabla
    search: '',
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Rut',
        align: 'start',
        sortable: false,
        value: 'rut',
      },
      { text: 'Email', value: 'email' },
      { text: 'Nombre', value: 'name' },
      { text: 'Ciudad', value: 'city' },
      { text: 'Dirrección', value: 'address' },
      { text: 'Info.', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      rut: '',
      email: '',
      name: '',
      city: '',
      address: '',
      sponsor: '',
      category: '',
      type: '',
      sku: '',
      price: '',
      coverage: '',
      value: '',
    },
    defaultItem: {
      rut: '',
      email: '',
      name: '',
      city: '',
      address: '',
      sponsor: '',
      category: '',
      type: '',
      sku: '',
      price: '',
      coverage: '',
      value: '',
    },
  }),
  mounted() {
  },
  methods: {
    next(data) {
      this.$emit('next', data)
    },
    prev() {
      this.$emit('prev')
    },
    initialize() {
      var aux;
      this.products.forEach((product, index) => {
        aux = {
          rut: product.client_id,
          email: product.client_email,
          name: product.client_name,
          city: product.client_city,
          address: product.client_adress,
          sponsor: product.sponsor,
          category: product.product_category,
          type: this.types[index],
          sku: product.product_sku,
          price: product.product_price + ' ' + product.product_unit_price,
          coverage: product.warranty_period + ' ' + product.warranty_period_unit,
          value: this.prices[index] + ' ' + product.product_unit_price,
        }
        this.desserts.push(aux)
      });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      this.close()
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  created() {
    this.initialize()
  },
};
</script>

<style lang="scss" scoped>
table {
  background: #f54077;
  border-radius: 30px;
  width: 80%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  tr {
    display: flex;
    justify-content: space-around;
    padding: 0.5rem 0rem;
    border-bottom: 1px solid gray;
    transition: background .1s;
    position: relative;
    z-index: 1;

    &:last-child {
      border: none;
    }

    &:first-child::after {
      background: transparent;
    }

    &:first-child:hover {
      cursor: default;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: -20px;
      right: -20px;
      height: 100%;
      // background: #fa4b7f;
      transform: scaleX(0);
      opacity: 0;
      transition: transform .5s ease-in-out, opacity 1s;
      transform-origin: right;
      margin-top: -.5rem;
      border-radius: 20px;
    }

    &:hover::after {
      transform-origin: left;
      opacity: 1;
      transform: scaleX(1.2);
    }

    &:hover {
      cursor: pointer;
    }

    th,
    td {
      width: 100%;
      color: rgb(255, 255, 255)
    }
  }
}
</style>